define('frontend-cp/models/macro-action-definition', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        label: _emberData.default.attr('string'),
        name: _emberData.default.attr('string'),
        options: _emberData.default.attr({ defaultValue: function defaultValue() {
                return [];
            } }),
        rarity: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        inputType: _emberData.default.attr('string'),
        valueType: _emberData.default.attr('string'),
        values: _emberData.default.attr(),
        attributes: _emberData.default.attr({ defaultValue: function defaultValue() {
                return [];
            } }),
        group: _emberData.default.attr('string'),
        resourceType: _emberData.default.attr('string')
    });
});