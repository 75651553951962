define('frontend-cp/models/case-field', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    fielduuid: _emberData.default.attr('string'),
    title: _emberData.default.attr('string', { defaultValue: '' }),
    fieldType: _emberData.default.attr('string'),
    key: _emberData.default.attr('string'),
    isRequiredForAgents: _emberData.default.attr('boolean', { defaultValue: false }),
    isRequiredOnResolution: _emberData.default.attr('boolean', { defaultValue: false }),
    isVisibleToCustomers: _emberData.default.attr('boolean', { defaultValue: false }),
    customerTitles: _emberData.default.hasMany('locale-field', { async: false }),
    isCustomerEditable: _emberData.default.attr('boolean', { defaultValue: false }),
    isRequiredForCustomers: _emberData.default.attr('boolean', { defaultValue: false }),
    descriptions: _emberData.default.hasMany('locale-field', { async: false }),
    regularExpression: _emberData.default.attr('string', { defaultValue: '' }),
    sortOrder: _emberData.default.attr('number'),
    isEnabled: _emberData.default.attr('boolean', { defaultValue: true }),
    isSystem: _emberData.default.attr('boolean', { defaultValue: false }),
    options: _emberData.default.hasMany('field-option', { async: false }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    priorities: _emberData.default.hasMany('case-priority', { async: true }),
    statuses: _emberData.default.hasMany('case-status', { async: true }),
    types: _emberData.default.hasMany('case-type', { async: true }),
    tag: _emberData.default.attr('string')
  });
});