define('frontend-cp/components/ko-admin/macros/edit/component', ['exports', 'frontend-cp/lib/macro-schema'], function (exports, _macroSchema) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var readOnly = Ember.computed.readOnly;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    macro: null,
    editedMacro: null,
    title: null,
    referenceData: null,

    onCancel: function onCancel() {},
    onSuccess: function onSuccess() {},


    i18n: service(),
    notification: service(),
    virtualModel: service(),

    teams: computed.alias('referenceData.teams'),
    selectedTeam: readOnly('editedMacro.visibleToTeam'),

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('referenceData')) {
        this.set('referenceData', {});
      }
    },


    actions: {
      addAction: function addAction() {
        this.get('editedMacro').addAction();
      },
      removeAction: function removeAction(action) {
        this.get('editedMacro').removeAction(action);
      },
      changeAction: function changeAction(action, previousDefinition) {
        if (previousDefinition) {
          this.get('editedMacro').changeAction(previousDefinition);
        }
      },
      saveMacro: function saveMacro() {
        var _this = this;

        var macro = this.get('macro');
        var editedMacro = this.get('editedMacro.macro');

        // Handle new macro actions
        var macroActions = this.get('editedMacro.macroActions');
        var newMacroActionNames = ['subject', 'brand', 'mailbox', 'clear_tags', 'change_tags'];
        var newMacroActions = macroActions.filter(function (action) {
          return newMacroActionNames.includes(action.name) || action.name.startsWith('customfield_');
        });
        editedMacro.set('actions', newMacroActions);

        return this.get('virtualModel').save(macro, editedMacro, _macroSchema.default).catch(function (error) {
          if (error && error.errors.findBy('code', 'ANY_FIELD_REQUIRED')) {
            _this.get('notification').add({
              type: 'error',
              title: _this.get('i18n').t('admin.macros.errors.actions.required'),
              autodismiss: true,
              dismissable: true
            });
          }

          throw error;
        });
      },
      setMacroSharing: function setMacroSharing(sharing) {
        var macro = this.get('editedMacro');

        switch (sharing) {
          case 'PRIVATE':
            macro.set('visibleToTeam', null);
            macro.set('visibilityType', 'PRIVATE');
            break;
          case 'ALL':
            macro.set('visibleToTeam', null);
            macro.set('visibilityType', 'ALL');
            break;
          case 'TEAM':
            macro.set('visibilityType', 'TEAM');
            break;
        }
      },
      selectTeam: function selectTeam(team) {
        this.get('editedMacro').set('visibleToTeam', team);
      }
    }
  });
});