define('frontend-cp/components/side-conversations-panel/conversations-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var observer = Ember.observer;
  exports.default = Component.extend({
    tagName: '',

    store: service(),
    notification: service(),
    i18n: service(),

    isVisible: false,
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    loadingPage: -1,

    conversations: [],

    groupedConversations: computed('conversations.@each.status', function () {
      var conversations = this.get('conversations') || [];
      var groups = conversations.reduce(function (acc, conversation) {
        var status = conversation.get('status');
        var key = status === 'open' ? 'open' : 'closed';
        acc[key].push(conversation);
        return acc;
      }, { open: [], closed: [] });

      return {
        open: groups.open,
        closed: groups.closed,
        hasOpen: groups.open.length > 0,
        hasClosed: groups.closed.length > 0
      };
    }),

    case: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.loadConversations(1);
    },
    loadConversations: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(page) {
        var offset, limit, sideConversations;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.prev = 0;

                this.set('loadingPage', page);
                offset = (page - 1) * this.pageSize;
                limit = this.pageSize;
                _context.next = 6;
                return this.get('store').query('side-conversation', {
                  caseId: this.get('case.id'), offset: offset, limit: limit
                });

              case 6:
                sideConversations = _context.sent;

                this.set('conversations', sideConversations);
                this.set('totalCount', sideConversations.meta.total);
                this.set('currentPage', page);
                _context.next = 15;
                break;

              case 12:
                _context.prev = 12;
                _context.t0 = _context['catch'](0);

                this.get('notification').error(this.get('i18n').t('generic.generic_error'));

              case 15:
                _context.prev = 15;

                this.set('loadingPage', -1);
                return _context.finish(15);

              case 18:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[0, 12, 15, 18]]);
      }));

      function loadConversations(_x) {
        return _ref.apply(this, arguments);
      }

      return loadConversations;
    }(),


    isCaseIdChanged: observer('case.id', function () {
      this.set('currentPage', 1);
      this.loadConversations(1);
    }),

    shouldRefetchConversationsObserver: observer('shouldRefetchConversations', function () {
      var _this = this;

      if (this.get('shouldRefetchConversations')) {
        var currentPage = this.get('currentPage');
        this.loadConversations(currentPage);
        setTimeout(function () {
          _this.get('refetchConversations')(false);
        });
      }
    }),

    // Computed property to determine which pages to display for pagination
    visiblePages: computed('currentPage', 'totalPages', function () {
      var totalPages = this.get('totalPages');
      var currentPage = this.get('currentPage');
      var maxPages = 5;
      var startPage = void 0,
          endPage = void 0;

      if (totalPages <= maxPages) {
        // Total pages less than max, so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // More than max pages, so calculate start and end pages
        var middle = Math.floor(maxPages / 2);
        if (currentPage <= middle) {
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + middle >= totalPages) {
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - middle;
          endPage = currentPage + middle;
        }
      }
      return Array.from({ length: endPage - startPage + 1 }, function (_, i) {
        return startPage + i;
      });
    }),

    totalPages: computed('totalCount', 'pageSize', function () {
      return Math.max(1, Math.ceil(this.get('totalCount') / this.get('pageSize')));
    }),

    actions: {
      previousPage: function previousPage() {
        if (this.get('currentPage') > 1) {
          var prevPage = this.get('currentPage') - 1;
          this.loadConversations(prevPage);
        }
      },
      firstPage: function firstPage() {
        if (this.get('currentPage') === 1) {
          return;
        }
        this.loadConversations(1);
      },
      goToPage: function goToPage(page) {
        if (page === this.get('currentPage')) {
          return;
        }
        this.loadConversations(page);
      },
      nextPage: function nextPage() {
        if (this.get('currentPage') < this.get('totalPages')) {
          var nextPage = this.get('currentPage') + 1;
          this.loadConversations(nextPage);
        }
      },
      goToLastPage: function goToLastPage() {
        if (this.get('currentPage') === this.get('totalPages')) {
          return;
        }
        var lastPage = this.get('totalPages');
        this.loadConversations(lastPage);
      }
    }
  });
});