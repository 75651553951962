define('frontend-cp/services/cache/agent-cache', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var Promise = Ember.RSVP.Promise;


  var ASSIGNEE_QUERY_DATA = {
    role: 'agent',
    limit: 500,
    fields: 'resource_type,is_enabled,full_name,teams,avatar,locale',
    include: ['team']
  };

  var MENTIONS_QUERY_DATA = {
    role: 'collaborator',
    limit: 500,
    fields: 'resource_type,is_enabled,full_name,avatar,emails',
    include: ['identity_email']
  };

  exports.default = Service.extend({
    storeCache: service(),

    getAgents: function getAgents() {
      var storeCache = this.get('storeCache');
      return storeCache.query('user', ASSIGNEE_QUERY_DATA, { ttl: 600000 }); // 10 minutes
    },
    getAgentsForMentions: function getAgentsForMentions() {
      var storeCache = this.get('storeCache');
      var allUsers = [];
      var offset = 0;
      var limit = MENTIONS_QUERY_DATA.limit;

      return new Promise(function (resolve) {
        var fetchPage = function fetchPage() {
          var queryParams = Object.assign({}, MENTIONS_QUERY_DATA, { offset: offset });

          storeCache.query('user', queryParams, { ttl: 600000 }).then(function (response) {
            allUsers = allUsers.concat(response.toArray());

            if (!response.meta || !response.meta.total || offset + limit >= response.meta.total) {
              resolve(allUsers);
            } else {
              offset += limit;
              fetchPage();
            }
          });
        };

        fetchPage();
      });
    },
    invalidateCache: function invalidateCache() {
      this.get('storeCache').invalidateCache('user', ASSIGNEE_QUERY_DATA);
      this.get('storeCache').invalidateCache('user', MENTIONS_QUERY_DATA);
    }
  });
});