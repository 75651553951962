define('frontend-cp/models/macro', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    usageCount: _emberData.default.attr('number'),
    lastUsedAt: _emberData.default.attr('date'),
    visibilityType: _emberData.default.attr('string', { defaultValue: 'PRIVATE' }),
    replyType: _emberData.default.attr('string'),
    replyContents: _emberData.default.attr('string'),
    assigneeType: _emberData.default.attr('string'),
    priorityAction: _emberData.default.attr('string'),
    addTags: _emberData.default.attr({ defaultValue: function defaultValue() {
        return [];
      } }),
    removeTags: _emberData.default.attr({ defaultValue: function defaultValue() {
        return [];
      } }),

    // Relationships
    visibleToTeam: _emberData.default.belongsTo('team', { async: true }),
    assignedTeam: _emberData.default.belongsTo('team', { async: false }),
    assignedAgent: _emberData.default.belongsTo('user', { async: false }),
    priority: _emberData.default.belongsTo('case-priority', { async: false }),
    status: _emberData.default.belongsTo('case-status', { async: false }),
    agent: _emberData.default.belongsTo('user', { async: false }),
    caseType: _emberData.default.belongsTo('case-type', { async: false }),
    tags: _emberData.default.hasMany('macro-tag', { async: false }),

    // read only
    visibility: _emberDataModelFragments.default.fragment('macro-visibility'),

    actions: _emberDataModelFragments.default.fragmentArray('macro-action', { defaultValue: [], async: false })
  });
});