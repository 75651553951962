define('frontend-cp/components/ko-time-tracking/component', ['exports', 'ember-service/inject', 'ember-concurrency', 'ember-diff-attrs', 'moment', 'npm:lodash'], function (exports, _inject, _emberConcurrency, _emberDiffAttrs, _moment, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    store: service(),
    notification: service(),
    session: service(),
    i18n: service(),
    metrics: service(),
    plan: service(),

    case: null,
    tracked: null,
    activities: null,
    timerHours: null,
    timerMinutes: null,
    timerSeconds: null,
    timerTotalSeconds: 0,
    editingEntry: null,
    editingEntryTime: null,
    timeWorked: null,
    timeBilled: null,
    isBillable: false,
    isTimerRunning: false,
    isEditing: false,
    isEnabled: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.get('checkIfEnabled').perform();
    },


    timerValueState: null,
    isBillableState: null,
    preserveTimer: function preserveTimer() {},

    didReceiveAttrs: (0, _emberDiffAttrs.default)('case', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      if (!changedAttrs || changedAttrs.case) {
        this.get('startTracking').cancelAll();
        this.get('updateLog').cancelAll();
        this.send('stopTimer');
        this.setProperties({
          'timeWorked': null,
          'timeBilled': null
        });

        // If timer value is found in the case state, resume timer
        if (this.get('timerValueState')) {
          this.set('timerTotalSeconds', this.get('timerValueState'));
          this.send('playPauseTimer');
        }
        if (this.get('isBillableState')) {
          this.send('updateIsBillable', this.get('isBillableState'));
        }

        this.get('fetchTracked').perform();
        this.get('fetchActivities').perform();
      }
    }),

    checkIfEnabled: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var settings, setting, isEnabled;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get('store').findAll('setting', { reload: true });

            case 2:
              settings = _context.sent;
              setting = settings.findBy('name', 'timetracking');
              isEnabled = setting && setting.get('toBoolean');


              this.set('isEnabled', isEnabled);

            case 6:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    isCaseClosed: computed.equal('case.status.statusType', 'CLOSED'),

    isCaseTrashed: computed.equal('case.state', 'TRASH'),

    entries: computed('tracked.entries', function () {
      return this.get('tracked.entries');
    }),

    hasActivities: computed('activities', function () {
      return this.get('activities').isAny('totalTimeSpent');
    }),

    isTimerDirty: computed('timerHours', 'timerMinutes', 'timerSeconds', function () {
      return !!(this.get('timerHours') || this.get('timerMinutes') || this.get('timerSeconds'));
    }),

    fetchTracked: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var tracked;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('store').queryRecord('timetracking-tracked', {
                case_id: this.get('case.id')
              });

            case 2:
              tracked = _context2.sent;


              this.setProperties({
                tracked: tracked,
                timeWorked: tracked.get('worked'),
                timeBilled: tracked.get('billed')
              });

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).drop(),

    fetchActivities: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var queryParams, activities;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              queryParams = {
                caseId: this.get('case.id')
              };


              if (this.get('plan').has('optimize_ui_fetch')) {
                queryParams.fields = '+case(-custom_fields,-requester,-creator,-last_assigned_by,-form,-last_replier,-last_updated_by),+agent(-organization,-teams,-custom_fields)';
              }

              _context3.next = 4;
              return this.get('store').query('timetracking-activity', queryParams);

            case 4:
              activities = _context3.sent;


              this.set('activities', activities);
              this.get('startTracking').perform();

            case 7:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).drop(),

    startTracking: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      var response;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              if (!(this.get('isCaseClosed') || this.get('isCaseTrashed'))) {
                _context4.next = 2;
                break;
              }

              return _context4.abrupt('return');

            case 2:
              _context4.next = 4;
              return (0, _emberConcurrency.timeout)(Ember.testing ? 0 : 30000);

            case 4:
              _context4.next = 6;
              return this.get('store').createRecord('timetracking-log', {
                case: this.get('case'),
                logType: 'VIEWED',
                timeSpent: 30,
                currentTime: (0, _moment.default)().unix(),
                activity: this.get('activity')
              }).save();

            case 6:
              response = _context4.sent;
              _context4.next = 9;
              return this.get('fetchActivities').perform();

            case 9:
              if (Ember.testing) {
                _context4.next = 14;
                break;
              }

              _context4.next = 12;
              return (0, _emberConcurrency.timeout)(60000);

            case 12:
              _context4.next = 14;
              return this.get('updateLog').perform(response);

            case 14:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })).drop(),

    updateLog: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5(response) {
      var log;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              _context5.next = 2;
              return this.get('store').findRecord('timetracking-log', response.get('id'));

            case 2:
              log = _context5.sent;


              log.set('currentTime', (0, _moment.default)().unix());
              _context5.next = 6;
              return log.save();

            case 6:
              if (!this.get('isEnabled')) {
                _context5.next = 9;
                break;
              }

              _context5.next = 9;
              return this.get('fetchActivities').perform();

            case 9:
              _context5.next = 11;
              return (0, _emberConcurrency.timeout)(60000);

            case 11:
              _context5.next = 13;
              return this.get('updateLog').perform(response);

            case 13:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })),

    startTimer: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee6() {
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              if (!this.get('isTimerRunning')) {
                _context6.next = 8;
                break;
              }

              this.incrementProperty('timerTotalSeconds');
              this.setProperties({
                timerHours: _npmLodash.default.padStart(Math.floor(this.timerTotalSeconds / 3600), 2, 0),
                timerMinutes: _npmLodash.default.padStart(Math.floor(this.timerTotalSeconds / 60 % 60), 2, 0),
                timerSeconds: _npmLodash.default.padStart(parseInt(this.timerTotalSeconds % 60), 2, 0)
              });
              this.preserveCurrentTimerState();
              _context6.next = 6;
              return (0, _emberConcurrency.timeout)(1000);

            case 6:
              _context6.next = 0;
              break;

            case 8:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    })).drop(),

    totalSeconds: computed('timerHours', 'timerMinutes', 'timerSeconds', function () {
      var hour = this.get('timerHours') || 0;
      var minutes = this.get('timerMinutes') || 0;
      var seconds = this.get('timerSeconds') || 0;
      return _moment.default.duration(hour + ':' + minutes + ':' + seconds, 'HH:mm:ss').asSeconds();
    }),

    updateEntry: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee7() {
      var launchDarkly, successMessage, totalSeconds, id, log, newLog, timeDifference;
      return regeneratorRuntime.wrap(function _callee7$(_context7) {
        while (1) {
          switch (_context7.prev = _context7.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');
              successMessage = '';
              totalSeconds = this.get('totalSeconds');

              if (!this.get('isEditing')) {
                _context7.next = 22;
                break;
              }

              id = this.get('editingEntry');
              _context7.next = 7;
              return this.get('store').findRecord('timetracking-log', id);

            case 7:
              log = _context7.sent;


              log.set('timeSpent', totalSeconds);

              _context7.prev = 9;
              _context7.next = 12;
              return log.save();

            case 12:
              _context7.next = 18;
              break;

            case 14:
              _context7.prev = 14;
              _context7.t0 = _context7['catch'](9);

              log.rollbackAttributes();
              totalSeconds.rollbackAttributes();

            case 18:

              this.set('isEditing', false);
              successMessage = this.get('i18n').t('cases.timetracking.messages.update');
              _context7.next = 38;
              break;

            case 22:
              if (this.get('isTimerRunning')) {
                this.send('playPauseTimer');
              }
              _context7.next = 25;
              return this.get('store').createRecord('timetracking-log', {
                agent: this.get('session.user'),
                case: this.get('case'),
                logType: this.get('isBillable') ? 'BILLED' : 'WORKED',
                tracked: this.get('tracked'),
                timeSpent: totalSeconds
              });

            case 25:
              newLog = _context7.sent;
              _context7.prev = 26;
              _context7.next = 29;
              return newLog.save();

            case 29:
              if (launchDarkly.get('release-eventTracking')) {
                this.get('metrics').trackEvent({
                  event: 'conversation_time_tracked',
                  object: this.get('case.id'),
                  type: this.get('isBillable') ? 'BILLED' : 'WORKED'
                });
              }
              _context7.next = 36;
              break;

            case 32:
              _context7.prev = 32;
              _context7.t1 = _context7['catch'](26);

              newLog.rollbackAttributes();
              totalSeconds.rollbackAttributes();

            case 36:

              this.set('editingEntryTime', null);
              successMessage = this.get('i18n').t('cases.timetracking.messages.add');

            case 38:
              timeDifference = totalSeconds - this.get('editingEntryTime');

              this.incrementProperty('timeWorked', timeDifference);
              if (this.get('isBillable')) {
                this.incrementProperty('timeBilled', timeDifference);
              }

              this.get('notification').add({
                type: 'success',
                title: successMessage,
                autodismiss: true
              });

              this.send('stopTimer');
              this.set('isBillable', false);

            case 44:
            case 'end':
              return _context7.stop();
          }
        }
      }, _callee7, this, [[9, 14], [26, 32]]);
    })),

    preserveCurrentTimerState: function preserveCurrentTimerState() {
      this.get('preserveTimer')(this.get('timerTotalSeconds'), this.get('isBillable'));
    },


    actions: {
      playPauseTimer: function playPauseTimer() {
        if (!this.get('isTimerRunning')) {
          this.get('startTimer').perform();
        }
        this.toggleProperty('isTimerRunning');
      },
      stopTimer: function stopTimer() {
        this.setProperties({
          timerHours: null,
          timerMinutes: null,
          timerSeconds: null,
          timerTotalSeconds: 0,
          isTimerRunning: false,
          isEditing: false,
          isBillable: false
        });

        // Reset timer value from case state
        this.get('preserveTimer')(null, null);
      },
      inputHours: function inputHours(e) {
        this.set('timerHours', e.target.value);
      },
      inputMinutes: function inputMinutes(e) {
        this.set('timerMinutes', e.target.value);
      },
      inputSeconds: function inputSeconds(e) {
        this.set('timerSeconds', e.target.value);
      },
      validateInput: function validateInput(e) {
        // Return `false` if not a number
        return !e.charCode || e.charCode >= 48 && e.charCode <= 57;
      },
      editEntry: function editEntry(e, entry) {
        var duration = _moment.default.duration(entry.get('timeSpent'), 'seconds');

        this.setProperties({
          isEditing: true,
          editingEntry: entry.get('id'),
          editingEntryTime: entry.get('timeSpent'),

          timerHours: _npmLodash.default.padStart(duration.hours(), 2, 0),
          timerMinutes: _npmLodash.default.padStart(duration.minutes(), 2, 0),
          timerSeconds: _npmLodash.default.padStart(duration.seconds(), 2, 0)
        });

        if (entry.get('logType') === 'BILLED') {
          this.set('isBillable', true);
        }
      },
      updateIsBillable: function updateIsBillable(newValue) {
        this.set('isBillable', newValue);
        this.preserveCurrentTimerState();
      },
      deductTotalTime: function deductTotalTime(e, entry) {
        this.decrementProperty('timeWorked', entry.get('timeSpent'));
        if (entry.get('logType') === 'BILLED') {
          this.decrementProperty('timeBilled', entry.get('timeSpent'));
        }
      }
    }
  });
});