define('frontend-cp/models/plan-limit', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    slas: _emberData.default.attr('number'),
    business_hours: _emberData.default.attr('number'),
    custom_security_policy: _emberData.default.attr('boolean'),
    brands: _emberData.default.attr('number'),
    agents: _emberData.default.attr('string'),
    collaborators: _emberData.default.attr('string'),
    attachment_size_limit: _emberData.default.attr('number'),
    teams: _emberData.default.attr('number'),
    custom_headers: _emberData.default.attr('number')
  });
});