define('frontend-cp/timelines/post', ['exports', 'frontend-cp/timelines/timeline', 'ember-concurrency'], function (exports, _timeline, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var assign = Ember.assign;
  var object = Ember.Object;
  var service = Ember.inject.service;
  exports.default = _timeline.default.extend({
    readMarker: null,
    filter: null,

    /**
     * Sent posts are ones which we have received from the API independently
     * of the timeline. Typically from a POST to `/api/v1/cases/:id/reply`.
     *
     * They are complete posts with all properties accounted for, we just
     * don’t yet know where they should appear in the timeline.
     *
     * We expect that at some later point the post will appear in the reponse
     * to `/api/v1/cases/:id/posts`, take its place in the timeline,
     * and can be safely removed from sentPosts (or simply ignored).
     *
     * Posts are added to this array with {@link addSentPosts}.
     *
     * @property sentPosts
     * @type Post[]
     */
    sentPosts: null,
    sendingOperations: null,

    firstPost: reads('posts.firstObject'),
    lastPost: reads('posts.lastObject'),

    plan: service(),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('sentPosts', []);
      this.set('sendingOperations', []);
    },


    /**
     * @method addSentPosts
     * @param {Post[]} posts
     */
    addSentPosts: function addSentPosts(posts) {
      this.get('sentPosts').addObjects(posts);
      this.trigger('didAddSentPosts');
    },
    addSendingOperation: function addSendingOperation(op) {
      this.get('sendingOperations').addObject(op);
      this.trigger('didAddSendingOperation');
    },
    notifySendingOperationUpdate: function notifySendingOperationUpdate(op) {
      this.trigger('didUpdateSendingOperation', op);
    },
    setFilter: function setFilter(filter) {
      if (this.get('filter') === filter) {
        return;
      }

      this.set('filter', filter);
      this.get('loadMostRecent').perform();
    },


    allPostsLoaded: computed('posts', 'serverTotalPosts', function () {
      var _getProperties = this.getProperties('posts', 'serverTotalPosts'),
          posts = _getProperties.posts,
          serverTotalPosts = _getProperties.serverTotalPosts;

      return posts.length === serverTotalPosts;
    }),

    lastReadPost: computed('posts.[]', 'readMarker', function () {
      var post = this._lastReadPost();
      var unreadPosts = this._unreadPosts();
      var messages = unreadPosts.filter(isMessage);

      if (messages.length) {
        return post;
      } else {
        return null;
      }
    }),

    lastMessage: computed('moreNewerPosts', 'posts.[]', function () {
      if (this.get('moreNewerPosts')) {
        return;
      }

      return this.get('posts').slice().reverse().find(isMessage);
    }),

    lastMessageFromACustomer: computed('moreNewerPosts', 'posts.[]', function () {
      if (this.get('moreNewerPosts')) {
        return;
      }

      return this.get('posts').slice().reverse().find(isMessageFromCustomer);
    }),

    newestUnreadPost: function newestUnreadPost() {
      var unreadPosts = this._unreadPosts();

      return unreadPosts.reverse().find(isMessage);
    },


    fetchNewerFromKRE: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              this.trigger('willFetchNewerFromKRE');
              _context.next = 3;
              return this.get('_fetchNewer').perform();

            case 3:
              this.trigger('didFetchNewerFromKRE');

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    loadPosition: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(_ref) {
      var postId = _ref.postId,
          noteId = _ref.noteId;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              this.clear();

              if (!postId) {
                _context2.next = 6;
                break;
              }

              _context2.next = 4;
              return this.get('fetchPosition').perform({ postId: postId });

            case 4:
              _context2.next = 9;
              break;

            case 6:
              if (!noteId) {
                _context2.next = 9;
                break;
              }

              _context2.next = 9;
              return this.get('fetchPosition').perform({ noteId: noteId });

            case 9:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    fetchPosition: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(_ref2) {
      var postId = _ref2.postId,
          noteId = _ref2.noteId;
      var isNote, store, expectedNumberOfPosts, afterParams, afterData, firstAfterPost, beforeParams, beforeData, total, params, data;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              isNote = Boolean(noteId);
              store = this.get('store');
              expectedNumberOfPosts = Math.round(this.get('limit') / 2);
              afterParams = void 0, afterData = void 0;

              if (!postId) {
                _context3.next = 11;
                break;
              }

              afterParams = this._paramsForAfterPost(postId, expectedNumberOfPosts);
              _context3.next = 8;
              return this.get('_fetch').perform(afterParams);

            case 8:
              afterData = _context3.sent;
              _context3.next = 15;
              break;

            case 11:
              if (!isNote) {
                _context3.next = 15;
                break;
              }

              _context3.next = 14;
              return store.adapterFor('note').getNoteSource('cases', this.get('parent.id'), noteId, expectedNumberOfPosts);

            case 14:
              afterData = _context3.sent;

            case 15:
              if (!(afterData && (afterData.data || afterData).toArray().length)) {
                _context3.next = 28;
                break;
              }

              firstAfterPost = void 0;

              if (isNote) {
                firstAfterPost = this.sortNotes(afterData.data || afterData, 'ASC')[0];
                firstAfterPost = object.create(firstAfterPost);
              } else {
                firstAfterPost = this.sortPosts(afterData.data || afterData, 'ASC')[0];
              }
              beforeParams = this._paramsForBeforePost(firstAfterPost.get('id'), expectedNumberOfPosts);
              _context3.next = 21;
              return this.get('_fetch').perform(beforeParams);

            case 21:
              beforeData = _context3.sent;
              total = afterData.total_count || afterData.meta.total_count;


              if (isNote) {
                store.pushPayload(afterData);
                afterData = afterData.posts.map(function (_ref3) {
                  var id = _ref3.id;
                  return store.peekRecord('post', id);
                });
              }

              this._updateInternalState(afterData, total, 'NEWER', expectedNumberOfPosts);
              this._updateInternalState(beforeData, total, 'OLDER', expectedNumberOfPosts);
              _context3.next = 33;
              break;

            case 28:
              // If there's nothing after the position we're requesting, we're at the end so just fetch most recent
              params = this._paramsForMostRecent();
              _context3.next = 31;
              return this.get('_fetch').perform(params);

            case 31:
              data = _context3.sent;

              this._updateInternalState(data, data.meta.total, 'NONE');

            case 33:

              this.trigger('didFetchPosition', { postId: postId, noteId: noteId });

            case 34:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    storeLocalReadState: function storeLocalReadState() {
      var post = this._findPostForLocalReadState();
      var id = post && post.get('id') || this.get('parent.readMarker.lastReadPostId');

      this.set('readMarker', id);
    },
    clearLocalReadState: function clearLocalReadState() {
      this.set('readMarker', null);
    },


    updateRemoteReadState: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      var post, adapter;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              post = this.newestUnreadPost();

              if (post) {
                _context4.next = 3;
                break;
              }

              return _context4.abrupt('return');

            case 3:
              adapter = this.get('store').adapterFor('post');
              _context4.next = 6;
              return adapter.markAsSeen(post);

            case 6:
              if (!this.get('parent.canReload')) {
                _context4.next = 9;
                break;
              }

              _context4.next = 9;
              return this.get('parent').reload();

            case 9:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })).restartable(),

    markAllAsSeen: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5() {
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              this.set('readMarker', null);
              _context5.next = 3;
              return this.get('updateRemoteReadState').perform();

            case 3:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })).restartable(),

    sortPosts: function sortPosts(posts) {
      var dir = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'ASC';

      return posts.toArray().sort(function (a, b) {
        if (dir === 'ASC') {
          if (a.get('createdAt').getTime() === b.get('createdAt').getTime()) {
            return parseInt(a.id) - parseInt(b.id);
          } else {
            return a.get('createdAt').getTime() - b.get('createdAt').getTime();
          }
        } else {
          if (a.get('createdAt').getTime() === b.get('createdAt').getTime()) {
            return parseInt(b.id) - parseInt(a.id);
          } else {
            return b.get('createdAt').getTime() - a.get('createdAt').getTime();
          }
        }
      });
    },
    sortNotes: function sortNotes(posts) {
      var dir = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'ASC';

      return posts.toArray().sort(function (a, b) {
        var adate = new Date(a.created_at);
        var bdate = new Date(b.created_at);
        if (dir === 'ASC') {
          if (adate.getTime() === bdate.getTime()) {
            return parseInt(a.id) - parseInt(b.id);
          } else {
            return adate.getTime() - bdate.getTime();
          }
        } else {
          if (adate.getTime() === bdate.getTime()) {
            return parseInt(b.id) - parseInt(a.id);
          } else {
            return bdate.getTime() - adate.getTime();
          }
        }
      });
    },
    _defaultParams: function _defaultParams() {
      var params = {
        parent: this.get('parent'),
        include: '*',
        limit: this.get('limit')
      };

      if (this.get('plan').has('optimize_ui_fetch')) {
        params.fields = '+original(+object(+original(+form(-fields)))),+original(+object(+original(-custom_fields)))';
      }

      return params;
    },
    _paramsForRestore: function _paramsForRestore() {
      return assign(this._defaultParams(), {
        filters: this.get('filter'),
        include: 'nothing',
        limit: 1
      });
    },


    /*
     * Regarding before_id and after_id, this is not a mistake:
     * - newer posts are considered to have occured before on the api
     * - older posts are considered to have occured after on the api
     */

    _paramsForAfterPost: function _paramsForAfterPost(postId, limit) {
      return assign(this._defaultParams(), {
        before_id: postId,
        filters: this.get('filter') || 'all',
        limit: limit
      });
    },
    _paramsForBeforePost: function _paramsForBeforePost(postId, limit) {
      return assign(this._defaultParams(), {
        after_id: postId,
        filters: this.get('filter') || 'all',
        limit: limit
      });
    },
    _paramsForOlder: function _paramsForOlder() {
      return assign(this._defaultParams(), {
        after_id: this.get('firstPost.id'),
        filters: this.get('filter') || 'all'
      });
    },
    _paramsForNewer: function _paramsForNewer() {
      return assign(this._defaultParams(), {
        before_id: this.get('lastPost.id'),
        filters: this.get('filter') || 'all'
      });
    },
    _paramsForMostRecent: function _paramsForMostRecent() {
      return assign(this._defaultParams(), {
        filters: this.get('filter') || 'all'
      });
    },


    _fetch: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee6(params) {
      var parent, store, result;
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              parent = params.parent;
              store = this.get('store');

              if (!(parent && parent.get('id'))) {
                _context6.next = 8;
                break;
              }

              _context6.next = 5;
              return store.query('post', params);

            case 5:
              return _context6.abrupt('return', _context6.sent);

            case 8:
              // Let’s construct something that behaves like an empty RecordArray Other
              // parts of the codebase seem to expect `total` or `total_count` so let’s
              // be flexible in our interface.
              result = [];

              result.meta = { total: 0, total_count: 0 };
              return _context6.abrupt('return', result);

            case 11:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    })),

    _unreadPosts: function _unreadPosts() {
      var post = this._lastReadPost();
      var posts = this.get('posts');
      var index = posts.indexOf(post);

      return posts.slice(index + 1);
    },
    _lastReadPost: function _lastReadPost() {
      var readMarker = this.get('readMarker');
      var posts = this.get('posts');

      return posts.findBy('id', readMarker);
    },


    /**
     * Given a remote read-marker pointing to some case-message in the timeline,
     * we want to move the red line to immediately before the *next* case-message
     * or note. This means we need to set our local read state (`this.readMarker`)
     * to the ID of the immediately preceeding post. This may be the same ID
     * stored in the remote read-marker, or it may be some activity inbetween.
     *
     * Crude ascii-art sketch:
     *
     * |
     * +- Message   <== unread marker points here
     * |
     * +- Activity
     * +- Activity
     * |
     * +----------- <== we want to put the red line here
     * |
     * +- Note
     * |
     * +- Activity
     * |
     * +- Message
     * |
     *
     */
    _findPostForLocalReadState: function _findPostForLocalReadState() {
      var posts = this.get('posts');
      var lastReadPostId = this.get('parent.readMarker.lastReadPostId');
      var lastReadPost = posts.findBy('id', lastReadPostId);

      if (!lastReadPost) {
        return;
      }

      var index = posts.indexOf(lastReadPost);
      var length = posts.get('length');

      // Find the post immediately before the next message or note.
      // This could be the post referenced by the remote read marker.
      for (; index < length; index++) {
        if (isMessageOrNote(posts[index + 1])) {
          return posts[index];
        }
      }
    }
  });


  function isMessage(post) {
    return post && !['activity', 'note'].includes(post.get('original.content.constructor.modelName'));
  }

  function isMessageFromCustomer(post) {
    return isMessage(post) && post.get('identity.user.role.roleType') === 'CUSTOMER';
  }

  function isMessageOrNote(post) {
    return post && post.get('original.content.constructor.modelName') !== 'activity';
  }
});