define('frontend-cp/adapters/case-ai-transcription', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'ai-transcription';
    },
    transcribeAudio: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(caseId) {
        var attachment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                return _context.abrupt('return', this.ajax('/api/v1/cases/' + caseId + '/ai-transcription/', 'POST', {
                  data: {
                    'ai_transcription': { attachmentId: parseInt(attachment, 10) }
                  }
                }));

              case 1:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function transcribeAudio(_x2) {
        return _ref.apply(this, arguments);
      }

      return transcribeAudio;
    }(),
    summarizeTranscription: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(caseId) {
        var attachment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                return _context2.abrupt('return', this.ajax('/api/v1/cases/' + caseId + '/ai-transcription/', 'POST', {
                  data: {
                    'ai_transcription': { transcribedText: attachment }
                  }
                }));

              case 1:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function summarizeTranscription(_x4) {
        return _ref2.apply(this, arguments);
      }

      return summarizeTranscription;
    }()
  });
});