define('frontend-cp/models/endpoint', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    title: attr('string', { defaultValue: '' }),
    fieldType: attr('string'),
    isEnabled: attr('boolean', { defaultValue: true }),
    lastAttemptResult: attr('string'),
    lastAttemptMessage: attr('string'),
    lastAttemptAt: attr('date'),

    requestMethod: attr(),
    requestContentType: attr(),

    webhookUrl: attr('string', { defaultValue: '' }),
    webhookUseHttpAuth: attr('string', false),
    webhookUsername: attr('string', { defaultValue: '' }),
    webhookPassword: attr('string', { defaultValue: '' }),
    webhookAuthMethod: attr('string', { defaultValue: 'none' }),
    webhookBearerToken: attr('string', { defaultValue: '' }),
    webhookApiKey: attr('string', { defaultValue: '' }),
    webhookApiValue: attr('string', { defaultValue: '' }),
    webhookCustomHeaders: attr('string', { defaultValue: '' }),

    emailDestination: attr('string', { defaultValue: '' }),
    emailSubject: attr('string', { defaultValue: '' }),
    slackUrl: attr('string', { defaultValue: '' })
  });
});