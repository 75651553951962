define('frontend-cp/models/macro-action', ['exports', 'ember-data-model-fragments', 'ember-data/attr'], function (exports, _emberDataModelFragments, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    name: (0, _attr.default)('string'),
    option: (0, _attr.default)('string'),
    value: (0, _attr.default)()
  });
});