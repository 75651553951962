define('frontend-cp/serializers/macro', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data) {
        if (Array.isArray(payload.data)) {
          payload.data.forEach(function (item) {
            if (Array.isArray(item.actions)) {
              item.actions = item.actions.map(function (action) {
                var actionId = action.id;
                return payload.resources.macro_action[actionId];
              });
            }
          });
        } else {
          if (Array.isArray(payload.data.actions)) {
            payload.data.actions = payload.data.actions.map(function (action) {
              var actionId = action.id;
              return payload.resources.macro_action[actionId];
            });
          }
        }
      }

      return this._super.apply(this, arguments);
    },
    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      var agent = resourceHash.assignee && resourceHash.assignee.agent;
      var team = resourceHash.assignee && resourceHash.assignee.team;
      var caseType = resourceHash.macro_type;

      if (agent) {
        resourceHash.assigned_agent = { id: agent.id, type: agent.resource_type };
      }
      if (team) {
        resourceHash.assigned_team = { id: team.id, type: team.resource_type };
      }
      if (caseType) {
        resourceHash.case_type = { id: caseType.id, type: caseType.resource_type };
      }

      if (resourceHash.visibility && resourceHash.visibility.team) {
        resourceHash.visible_to_team = resourceHash.visibility.team;
      }

      Reflect.deleteProperty(resourceHash, 'type');
      return this._super.apply(this, arguments);
    },
    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      if (resourceHash.assignee) {
        resourceHash.assignee_type = resourceHash.assignee.type;
      }

      if (resourceHash.visibility && resourceHash.visibility.type) {
        resourceHash.visibility_type = resourceHash.visibility.type;
      }

      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      if (!json.assignee_type) {
        Reflect.deleteProperty(json, 'assignee_type');
      }

      json.type_id = json.case_type_id;
      Reflect.deleteProperty(json, 'case_type_id');

      return json;
    }
  });
});