define('frontend-cp/utils/is-system-tag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isSystemTag;
  /**
   * Checks if a tag is a system tag
   * @param {Object} tag - The tag object to check
   * @returns {Boolean} - True if the tag is a system tag, false otherwise
   */
  function isSystemTag(tag) {
    if (!tag) {
      return false;
    }

    return tag.tagtype === 'SYSTEM';
  }
});