define('frontend-cp/components/ko-timeline-2/list/item/attachment/component', ['exports', 'frontend-cp/components/ko-timeline-2/list/item/attachment/styles'], function (exports, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var service = Ember.inject.service;
  var $ = Ember.$;
  exports.default = Component.extend({
    tagName: '',
    store: service(),
    timeline: service(),
    metrics: service(),

    attachment: null,
    thumbnailSize: 200,

    isTranscriptable: computed('attachment.{name,size}', function () {
      var name = this.get('attachment.name') || '';
      var size = this.get('attachment.size') || 0;
      var allowedExtensions = ['mp3', 'mpeg', 'wav', 'mp4'];
      var maxSize = 20 * 1024 * 1024; // 20 MB in bytes

      // Extract the file extension
      var fileExtension = name.split('.').pop().toLowerCase();

      // Check if the file extension is allowed and the size is within the limit
      if (allowedExtensions.includes(fileExtension) && size < maxSize) {
        return true;
      } else {
        return false;
      }
    }),

    thumbnail: computed('attachment.thumbnails.[]', function () {
      var size = this.get('thumbnailSize');
      var filtered = this.get('attachment.thumbnails').filter(function (thumbnail) {
        var width = typeof thumbnail.get === 'function' ? thumbnail.get('width') : get(thumbnail, 'width');
        return width <= size;
      });
      return filtered.get('lastObject');
    }),

    icon: computed('attachment.name', 'attachment.attachmentType', function () {
      var name = this.get('attachment.name') || '';
      var ext = name.slice(name.lastIndexOf('.') + 1, name.length).toUpperCase();

      var mimeType = this.get('attachment.attachmentType') || '';
      var type = mimeType.slice(0, mimeType.indexOf('/')).toUpperCase();
      var subtype = mimeType.slice(mimeType.lastIndexOf('/') + 1, mimeType.length).toUpperCase();

      var icon = 'attachment/icon--file_';

      var static_icon_ext = ['AI', 'CSS', 'DOC', 'HTML', 'PDF', 'PPT', 'PSD', 'SK', 'TXT', 'XD', 'XLS', 'ZIP'];
      if (static_icon_ext.includes(ext)) {
        return icon + ext;
      }

      switch (type) {
        case 'AUDIO':
          return icon + 'Audio';
        case 'IMAGE':
          return icon + 'Image';
        case 'VIDEO':
          return icon + 'Video';
        case 'TEXT':
          return icon + 'TXT';
      }

      if (subtype) {
        if (subtype.includes('spreadsheet')) {
          return icon + 'XLS';
        }
        if (subtype.includes('presentation')) {
          return icon + 'PPT';
        }
        if (subtype.includes('image')) {
          return icon + 'Image';
        }
        if (subtype.includes('graphics')) {
          return icon + 'Image';
        }
        if (subtype.includes('document')) {
          return icon + 'DOC';
        }
        if (subtype.includes('compressed')) {
          return icon + 'ZIP';
        }
      }

      return icon + 'Generic';
    }),

    actions: {
      triggerPreview: function triggerPreview(event) {
        event.preventDefault();
        event.stopPropagation();
        $(event.currentTarget).parents('.' + _styles.default.attachment).find('.' + _styles.default.thumbnail + ' > div').click();
      },
      triggerTranscribe: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(event) {
          var store, caseTranscriptionAdapter, attachmentId, attachmentName, caseId, transcription;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  event.preventDefault();
                  event.stopPropagation();

                  if (this.get('isTranscriptable')) {
                    _context.next = 5;
                    break;
                  }

                  this.set('error', 'The file type is not supported for transcription.');
                  return _context.abrupt('return');

                case 5:
                  store = this.get('store');
                  caseTranscriptionAdapter = store.adapterFor('case-ai-transcription');

                  this.set('isTranscribing', true);
                  _context.prev = 8;

                  this.get('metrics').trackEvent({
                    event: 'AI Transcription Added to Case',
                    caseId: this.get('case').id
                  });
                  attachmentId = this.get('attachment.id');
                  attachmentName = this.get('attachment.name');
                  caseId = this.get('case').id;
                  _context.next = 15;
                  return caseTranscriptionAdapter.transcribeAudio(caseId, attachmentId, attachmentName);

                case 15:
                  transcription = _context.sent;

                  this.set('transcriptionResult', transcription);
                  /* this.get('fetchNewerPosts').perform();*/
                  _context.next = 22;
                  break;

                case 19:
                  _context.prev = 19;
                  _context.t0 = _context['catch'](8);

                  this.get('notificationService').error(this.get('i18n').t('generic.generic_error'));

                case 22:
                  _context.prev = 22;

                  this.set('isTranscribing', false);

                  return _context.finish(22);

                case 25:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this, [[8, 19, 22, 25]]);
        }));

        function triggerTranscribe(_x) {
          return _ref.apply(this, arguments);
        }

        return triggerTranscribe;
      }()
    }

  });
});