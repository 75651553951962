define('frontend-cp/components/ko-admin/apps/endpoints/form/component', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var or = Ember.computed.or;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var set = Ember.set;
  var service = Ember.inject.service;
  var isBlank = Ember.isBlank;
  exports.default = Component.extend({
    // Attributes
    endpoint: null,
    editedEndpoint: null,
    title: null,
    schema: null,

    onSave: function onSave() {},
    onCancel: function onCancel() {},
    onDelete: function onDelete() {},

    // Services
    confirmation: service(),
    virtualModel: service(),
    plan: service(),

    // CPs
    requestMethods: computed(function () {
      return [{ id: 'GET' }, { id: 'POST' }, { id: 'PUT' }, { id: 'PATCH' }, { id: 'DELETE' }];
    }),

    requestMethod: computed('editedEndpoint.requestMethod', 'requestMethods', function () {
      var requestMethod = this.get('editedEndpoint.requestMethod');
      return this.get('requestMethods').find(function (_ref) {
        var id = _ref.id;
        return id === requestMethod;
      });
    }),

    requestContentTypes: computed(function () {
      return [{ id: 'FORM' }, { id: 'XML' }, { id: 'JSON' }];
    }),

    requestContentType: computed('editedEndpoint.requestContentType', 'requestContentTypes', function () {
      var requestContentType = this.get('editedEndpoint.requestContentType');
      return this.get('requestContentTypes').find(function (_ref2) {
        var id = _ref2.id;
        return id === requestContentType;
      });
    }),

    showContentType: computed('editedEndpoint.requestMethod', function () {
      return ['POST', 'PUT', 'PATCH'].indexOf(this.get('editedEndpoint.requestMethod')) > -1;
    }),

    isDisabled: or('save.isRunning', 'performDelete.isRunning'),

    // Custom headers management
    maxHeaders: computed(function () {
      return this.get('plan.limits.custom_headers') || 5;
    }),

    canAddHeader: computed('editedEndpoint.webhookCustomHeaders', function () {
      var headers = this.get('parsedHeaders');
      return headers.length < this.get('maxHeaders');
    }),

    // Compute headers from the webhookCustomHeaders string
    parsedHeaders: computed('editedEndpoint.webhookCustomHeaders', function () {
      var jsonString = this.get('editedEndpoint.webhookCustomHeaders') || '{}';
      try {
        var parsed = JSON.parse(jsonString);
        return Object.entries(parsed).map(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
              key = _ref4[0],
              value = _ref4[1];

          return { key: key, value: value };
        });
      } catch (e) {
        return [];
      }
    }),

    // Format headers for the template
    headers: computed('parsedHeaders', {
      get: function get() {
        return this.get('parsedHeaders');
      },
      set: function set(_, headers) {
        var newHeaders = {};
        headers.forEach(function (_ref5) {
          var key = _ref5.key,
              value = _ref5.value;

          newHeaders[key] = value;
        });
        this.set('editedEndpoint.webhookCustomHeaders', JSON.stringify(newHeaders));
        return headers;
      }
    }),

    save: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var endpoint, editedEndpoint;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              endpoint = this.get('endpoint');
              editedEndpoint = this.get('editedEndpoint');

              if (!(!endpoint.get('isNew') && endpoint.get('requestContentType') !== editedEndpoint.get('requestContentType'))) {
                _context.next = 5;
                break;
              }

              _context.next = 5;
              return this.get('confirmation').confirm({
                intlConfirmLabel: 'generic.confirm_button',
                intlConfirmationBody: 'admin.apps.endpoints.confirm_content_change.body',
                intlConfirmationHeader: 'admin.apps.endpoints.confirm_content_change.title'
              });

            case 5:
              _context.next = 7;
              return this.get('virtualModel').save(endpoint, editedEndpoint, this.get('schema'));

            case 7:
              this.get('onSave')();

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    confirmDelete: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmationBody: 'admin.apps.endpoints.labels.delete_confirmation',
                intlConfirmationHeader: 'admin.apps.endpoints.labels.confirm_delete',
                intlConfirmLabel: 'generic.confirm.delete_button'
              });

            case 2:
              _context2.next = 4;
              return this.get('performDelete').perform();

            case 4:
              this.get('onDelete');

            case 5:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    performDelete: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return this.get('endpoint').destroyRecord();

            case 2:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    // Method to update auth method and clear irrelevant fields
    _updateAuthMethod: function _updateAuthMethod(value) {
      var _this = this;

      var existingValue = this.get('editedEndpoint.webhookAuthMethod');
      if (existingValue === value) return;
      if (!this._storedCredentials) {
        this._storedCredentials = {};
      }

      var credentialFields = {
        basic: ['webhookUsername', 'webhookPassword'],
        bearer: ['webhookBearerToken'],
        apikey: ['webhookApiKey', 'webhookApiValue']
      };

      var storeCredentials = function storeCredentials(method) {
        if (!credentialFields[method]) return;
        credentialFields[method].forEach(function (field) {
          _this._storedCredentials[field] = _this.get('editedEndpoint.' + field);
        });
      };

      var clearCredentials = function clearCredentials(method) {
        if (!credentialFields[method]) return;
        var resetFields = {};
        credentialFields[method].forEach(function (field) {
          resetFields['editedEndpoint.' + field] = '';
        });
        _this.setProperties(resetFields);
      };

      var restoreCredentials = function restoreCredentials(method) {
        if (!credentialFields[method]) return;
        var restoredFields = {};
        credentialFields[method].forEach(function (field) {
          if (_this._storedCredentials[field]) {
            restoredFields['editedEndpoint.' + field] = _this._storedCredentials[field];
          }
        });
        _this.setProperties(restoredFields);
      };

      if (existingValue) {
        storeCredentials(existingValue);
        clearCredentials(existingValue);
      }
      restoreCredentials(value);

      this.setProperties({
        'editedEndpoint.webhookAuthMethod': value,
        'editedEndpoint.webhookUseHttpAuth': value === 'basic' // For backward compatibility
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('editedEndpoint.webhookAuthMethod')) {
        this.set('editedEndpoint.webhookAuthMethod', 'none'); // Default to none if not set
      }

      if (this.get('editedEndpoint.webhookUseHttpAuth')) {
        this.set('editedEndpoint.webhookAuthMethod', 'basic'); // For backward compatibility
      }

      // Initialize custom headers array if not present
      if (!this.get('editedEndpoint.webhookCustomHeaders')) {
        this.set('editedEndpoint.webhookCustomHeaders', JSON.stringify({}));
      }
    },


    // Authentication method CP
    authMethod: computed('editedEndpoint.webhookAuthMethod', {
      get: function get() {
        return this.get('editedEndpoint.webhookAuthMethod');
      },
      set: function set(_, value) {
        this._updateAuthMethod(value);
        return value;
      }
    }),

    actions: {
      changeAuthMethod: function changeAuthMethod(value) {
        this._updateAuthMethod(value);
      },


      // Custom headers actions
      addHeader: function addHeader() {
        var headers = this.get('headers');
        var hasEmptyName = false;
        var hasEmptyValue = false;

        // Check for empty keys and values and mark them for highlighting
        headers.forEach(function (header) {
          if (isBlank(header.key)) {
            set(header, 'hasError', true);
            hasEmptyName = true;
          } else {
            set(header, 'hasError', false);
          }
          if (isBlank(header.value)) {
            set(header, 'valueError', true);
            hasEmptyValue = true;
          } else {
            set(header, 'valueError', false);
          }
        });

        // If there are empty keys or values, don't add a new header
        if (hasEmptyName || hasEmptyValue) {
          return;
        }

        if (this.get('canAddHeader')) {
          this.set('headers', [].concat(_toConsumableArray(headers), [{ key: '', value: '' }]));
        }
      },
      removeHeader: function removeHeader(header) {
        var newHeaders = this.get('headers').filter(function (h) {
          return h !== header;
        });
        this.set('headers', newHeaders);
      },
      updateHeaderPair: function updateHeaderPair(header, attr, e) {
        set(header, attr, e.target.value);

        // Clear error state when user types in the name field
        if (attr === 'key' && !isBlank(e.target.value)) {
          set(header, 'hasError', false);
        }

        // Clear error state when user types in the value field
        if (attr === 'value' && !isBlank(e.target.value)) {
          set(header, 'valueError', false);
        }

        this.set('headers', this.get('headers').slice());
      }
    }
  });
});