define('frontend-cp/serializers/macro-action-definition', ['exports', 'frontend-cp/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.data.forEach(function (item, index) {
        item.id = index + 1;
      });
      return this._super.apply(this, arguments);
    }
  });
});