define("frontend-cp/components/ko-timeline-2/list/ai-suggestion-bubble/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "ai-suggestion-container": "ko-timeline-2_list_ai-suggestion-bubble__ai-suggestion-container_18v83x",
    "ai-suggestion-icon": "ko-timeline-2_list_ai-suggestion-bubble__ai-suggestion-icon_18v83x",
    "ai-suggestion-content-wrapper": "ko-timeline-2_list_ai-suggestion-bubble__ai-suggestion-content-wrapper_18v83x",
    "ai-suggestion-text": "ko-timeline-2_list_ai-suggestion-bubble__ai-suggestion-text_18v83x",
    "ai-suggestion-bubble": "ko-timeline-2_list_ai-suggestion-bubble__ai-suggestion-bubble_18v83x",
    "ai-suggestion-action-button": "ko-timeline-2_list_ai-suggestion-bubble__ai-suggestion-action-button_18v83x",
    "ai-suggestion-content": "ko-timeline-2_list_ai-suggestion-bubble__ai-suggestion-content_18v83x",
    "expanded": "ko-timeline-2_list_ai-suggestion-bubble__expanded_18v83x",
    "ai-suggestion-read-more-button": "ko-timeline-2_list_ai-suggestion-bubble__ai-suggestion-read-more-button_18v83x",
    "action-button-container": "ko-timeline-2_list_ai-suggestion-bubble__action-button-container_18v83x",
    "full-screen-loader": "ko-timeline-2_list_ai-suggestion-bubble__full-screen-loader_18v83x"
  };
});