define('frontend-cp/components/ko-on-boarding/setup/component', ['exports', 'ember-concurrency', 'frontend-cp/config/environment'], function (exports, _emberConcurrency, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var Component = Ember.Component;
  var getOwner = Ember.getOwner;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var observer = Ember.observer;
  var EmberObject = Ember.Object;
  var next = Ember.run.next;
  exports.default = Component.extend({
    session: service(),
    store: service(),
    metrics: service(),
    notification: service(),
    i18n: service(),
    routing: service('-routing'),
    onBoardingStatus: service(),
    plan: service(),
    agentCache: service('cache/agent-cache'),

    brand: {},
    newTeams: [],
    invitation: {},
    teams: [],
    roles: [],
    twitterAccount: [],
    facebookPage: [],
    params: {},
    agentMetrics: {},

    // Default messenger styling
    primaryColor: '#F1703F',
    activeSolidColor: '#FF3B30',
    activeGradient: '-192deg, #40364D 37%, #9B4779 100%',
    patternUrl: _environment.default.messengerAssetsUrl + '1--dark.svg',
    backgroundMode: 'gradient',

    // State
    activePopup: 0,
    hideEmailSetupTitle: false,
    hideMessengerSetupTitle: false,
    hideAgentSetupTitle: false,
    emailPopupRevisited: false,
    emailStep: 'initial',
    socialSetup: 'initial',
    messengerSetup: 'initial',
    teamSetup: 'initial',
    agentSetup: 'initial',
    agentsRows: 0,
    emailToBeCreated: null,
    internalDisplayEmail: '',
    defaultTeamTitle: '',
    nextStep: null,
    pages: null,
    availablePages: null,
    fetchingPages: false,
    importingPages: false,
    twitterButtonDisabled: false,
    isTwitterloading: false,
    twitterConnected: false,
    facebookButtonDisabled: false,
    importPagesButtonDisabled: true,
    isFacebookConnecting: false,
    facebookPagesConnected: false,
    grayoutSocial: false,
    grayoutAgents: false,
    isLoading: false,

    onComplete: function onComplete() {},

    init: function init() {
      this._super.apply(this, arguments);

      this.setActiveStep();
      this.getNextStep();
      this._setInitialStatus();

      this.set('activePopup', 1);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unloadUnsavedMailboxes();
    },


    allDone: computed('onBoardingStatus.pendingSteps', function () {
      if (!this.get('onBoardingStatus.pendingSteps') && this.get('hasTried3Conversations')) {
        return true;
      }
    }),

    hasTried3Conversations: computed.gt('onBoardingStatus.completedCases', 2),

    isDarkSolidColor: computed('activeSolidColor', function () {
      var solidColor = this.get('activeSolidColor');
      if (this._getColorDarkness(solidColor) < 172) {
        return true;
      }
    }),

    isDarkGradient: computed('activeGradient', function () {
      var gradientValue = this.get('activeGradient');
      if (gradientValue) {
        var colors = gradientValue.match(/#\w{6}/g);
        var lightness1 = this._getColorDarkness(colors[0]);
        var lightness2 = this._getColorDarkness(colors[1]);

        if (lightness1 < 135 && lightness2 < 190) {
          return true;
        }
      }
    }),

    isLightText: computed('isDarkSolidColor', 'isDarkGradient', 'backgroundMode', function () {
      var mode = this.get('backgroundMode');
      var darkGradient = this.get('isDarkGradient');
      var darkSolidColor = this.get('isDarkSolidColor');

      if (mode === 'gradient' && darkGradient || mode === 'color' && darkSolidColor) {
        return true;
      }
    }),

    title: computed(function () {
      return this.get('i18n').t('admin.messenger.tabs.customize.title_default_value');
    }),

    welcomeMessage: computed(function () {
      return this.get('i18n').t('admin.messenger.tabs.customize.welcome_default_value', { brand: this.get('brand.firstObject.name') });
    }),

    publicLocales: computed(function () {
      return this.get('store').peekAll('locale').filterBy('isPublic');
    }),

    titleLocaleFields: computed('publicLocales', function () {
      var result = this.get('publicLocales').map(function (localeModel) {
        var locale = localeModel.get('locale');
        var localeField = EmberObject.create({ locale: locale, translation: '' });
        return localeField;
      });
      return result;
    }),

    welcomeMessageLocaleFields: computed('publicLocales', function () {
      var result = this.get('publicLocales').map(function (localeModel) {
        var locale = localeModel.get('locale');
        var localeField = EmberObject.create({ locale: locale, translation: '' });
        return localeField;
      });
      return result;
    }),

    preview: computed('title', 'welcomeMessage', 'activeGradient', 'activeSolidColor', 'backgroundMode', 'primaryColor', 'isOnlinePresenceEnabled', 'isArticlesEnabled', 'isTwitterEnabled', 'selectedSection', 'selectedTwitterAccount', function () {
      return EmberObject.create({
        title: this.get('title'),
        welcomeMessage: this.get('welcomeMessage'),
        patternUrl: this.get('patternUrl'),
        activeGradient: this.get('activeGradient'),
        activeSolidColor: this.get('activeSolidColor'),
        backgroundMode: this.get('backgroundMode'),
        isLightText: this.get('isLightText'),
        primaryColor: this.get('primaryColor'),
        lastActiveAgents: this.get('agentMetrics.lastActiveAgents'),
        isOnlinePresenceEnabled: true,
        isArticlesEnabled: false,
        isTwitterEnabled: false
      });
    }),

    generatedCode: computed('preview', 'titleLocaleFields.@each.translation', 'welcomeMessageLocaleFields.@each.translation', function () {
      var _this = this;

      var apiUrl = 'https://' + this.get('brand.firstObject.subDomain') + '.' + this.get('brand.firstObject.domain') + '/api/v1';
      // Escaping the quotes from brand name since they can mess up the embed code.
      var brandName = this.get('brand.firstObject.name').replace(/"/g, '\\"');
      var primaryColor = this.get('primaryColor');
      var textColor = this.get('isLightText') ? '#FFFFFF' : '#2D3138';
      var presenceEnabled = true;
      var articlesEnabled = false;
      var twitterEnabled = false;
      var twitterAccountId = null;
      var sectionId = null;
      var pattern = this.get('patternUrl');
      var background = this.get('backgroundMode') === 'color' ? this.get('activeSolidColor') : this.get('activeGradient');
      var homeTitles = this.get('titleLocaleFields').map(function (locale, index) {
        return {
          locale: locale.get('locale'),
          translation: index === 0 ? _this.get('title') : locale.get('translation')
        };
      });
      var homeWelcomeMessages = this.get('welcomeMessageLocaleFields').map(function (locale, index) {
        return {
          locale: locale.get('locale'),
          translation: index === 0 ? _this.get('welcomeMessage') : locale.get('translation')
        };
      });

      return '<script>(function(d,a){function c(){var b=d.createElement("script");b.async=!0;b.type="text/javascript";b.src=a._settings.messengerUrl;b.crossOrigin="anonymous";var c=d.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}window.kayako=a;a.readyQueue=[];a.newEmbedCode=!0;a.ready=function(b){a.readyQueue.push(b)};a._settings={apiUrl:"' + apiUrl + '",teamName:"' + brandName + '",homeTitles:' + JSON.stringify(homeTitles) + ',homeSubtitles:' + JSON.stringify(homeWelcomeMessages) + ',messengerUrl:"https://' + this.get('brand.firstObject.subDomain') + '.kayakocdn.com/messenger",realtimeUrl:"' + _environment.default.kreSocket + '",widgets:{presence:{enabled:' + presenceEnabled + '},twitter:{enabled:' + twitterEnabled + ',twitterHandle:"' + twitterAccountId + '"},articles:{enabled:' + articlesEnabled + ',sectionId:' + sectionId + '}},styles:{primaryColor:"' + primaryColor + '",homeBackground:"' + background + '",homePattern:"' + pattern + '",homeTextColor:"' + textColor + '"}};window.attachEvent?window.attachEvent("onload",c):window.addEventListener("load",c,!1)})(document,window.kayako||{});</script>';
    }),

    fullDomain: computed(function () {
      return '@' + this.get('brand.firstObject.subDomain') + '.' + this.get('brand.firstObject.domain');
    }),

    hideSocialPopupFooter: computed('socialSetup', function () {
      switch (this.get('socialSetup')) {
        case 'connect':
          return false;
        case 'connectpages':
          return false;
        default:
          return true;
      }
    }),

    isFacebookPagesPopup: computed('socialSetup', function () {
      return this.get('socialSetup') === 'connectpages';
    }),

    emailToBeConnected: computed(function () {
      return this.get('store').createRecord('mailbox', {
        address: ''
      });
    }),

    agentModalDimensions: computed('agentSetup', 'agentsRows', function () {
      switch (this.get('agentSetup')) {
        case 'add':
          if (this.get('agentsRows')) {
            var modalHeight = 300 + (this.get('agentsRows') - 1) * 38;
            return 'width: 900px; height: ' + modalHeight + 'px';
          }
          return 'width:900px;height:300px';
        case 'done':
          return 'width:420px;height:440px';
        default:
          return 'width:600px;height:400px';
      }
    }),

    socialModalDimensions: computed('socialSetup', function () {
      switch (this.get('socialSetup')) {
        case 'connect':
          return 'width:900px;height:570px';
        case 'connectpages':
          return 'width:420px;height:400px;';
        case 'done':
          return 'width:420px;height:450px';
        default:
          return 'width:600px;height:400px';
      }
    }),

    teamModalDimensions: computed('teamSetup', function () {
      switch (this.get('teamSetup')) {
        case 'add':
          return 'width:780px;height:480px';
        case 'done':
          return 'width:420px;height:450px';
        default:
          return 'width:600px;height:400px';
      }
    }),

    referenceData: computed('roles', 'teams', function () {
      return {
        roles: this.get('roles'),
        teams: this.get('teams')
      };
    }),

    progressDidChange: observer('onBoardingStatus.progress', function () {
      this.setActiveStep();
      this.getNextStep();
    }),

    multipleTeamsAllowed: computed(function () {
      return this.get('plan.limits.teams') > 1;
    }),

    _setInitialStatus: function _setInitialStatus() {
      var _this2 = this;

      if (this.get('onBoardingStatus.progress.account_setup')) {
        this.set('emailPopupRevisited', true);
      }

      // Show twitter/facebook modals if URL has the required params
      var params = this.get('params');
      if (params.code && params.state) {
        this.connectFacebook(params);
      }
      if (params.oauth_token && params.oauth_verifier) {
        this.connectTwitter(params);
      }

      var pages = this.get('pages');
      if (!pages) {
        this.set('pages', A());
      }

      // Disable facebook/twitter buttons if already connected
      if (this.get('twitterAccount.length')) {
        this.setProperties({
          twitterConnected: true,
          twitterButtonDisabled: true,
          socialSetup: 'connect'
        });
      }
      if (this.get('facebookPage.length')) {
        this.setProperties({
          facebookPagesConnected: true,
          facebookButtonDisabled: true,
          socialSetup: 'connect'
        });
      }

      // Check if agents have been invited already
      this.get('agentCache').getAgents().then(function (agents) {
        if (agents.get('length') < 2 && _this2.get('onBoardingStatus.progress.agent_added')) {
          _this2.setProperties({
            grayoutAgents: true,
            agentSetup: 'initial',
            hideAgentSetupTitle: false
          });
        }
      });
    },
    connectTwitter: function connectTwitter(params) {
      var _this3 = this;

      this.setProperties({
        activePopup: 2,
        socialSetup: 'connect',
        isTwitterloading: true
      });

      this.get('store').createRecord('twitter-account-callback', {
        oauthToken: params.oauth_token,
        oauthVerifier: params.oauth_verifier
      }).save().then(function () {
        _this3.get('notification').add({
          type: 'success',
          title: _this3.get('i18n').t('onboarding.setup.popup.social.connect.twitterconnected'),
          autodismiss: true
        });
        _this3.setProperties({
          twitterConnected: true,
          twitterButtonDisabled: true
        });
      }).finally(function () {
        _this3.get('routing.router').replaceWith('/agent/welcome');
        _this3.set('isTwitterloading', false);
      });
    },
    connectFacebook: function connectFacebook(params) {
      var _this4 = this;

      this.setProperties({
        activePopup: 2,
        socialSetup: 'connectpages',
        importPagesButtonDisabled: true,
        fetchingPages: true
      });

      this.get('store').queryRecord('facebook-account-callback', {
        code: params.code,
        state: params.state,
        callback: '/agent/welcome#facebook'
      }).finally(function () {
        _this4.get('routing.router').replaceWith('/agent/welcome');
        _this4.get('fetchAvailablePages').perform();
      });
    },
    _getColorDarkness: function _getColorDarkness(hexCode) {
      var hex = hexCode.replace('#', '');
      var r = parseInt(hex.substring(0, 2), 16);
      var g = parseInt(hex.substring(2, 4), 16);
      var b = parseInt(hex.substring(4, 6), 16);
      return Math.round((parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000);
    },
    setActiveStep: function setActiveStep() {
      var progress = this.get('onBoardingStatus.progress');

      if (progress.account_setup) {
        this.setProperties({
          emailStep: 'created',
          hideEmailSetupTitle: true
        });
      }

      if (progress.account_connected) {
        if (this.get('twitterButtonDisabled') && this.get('facebookButtonDisabled')) {
          this.setProperties({
            socialSetup: 'done',
            hideSocialSetupTitle: true
          });
        } else {
          this.set('grayoutSocial', true);
        }
      }

      if (progress.setup) {
        this.set('messengerSetup', 'done');
      }

      if (progress.team_added) {
        this.set('teamSetup', 'done');
      }

      if (progress.agent_added) {
        this.setProperties({
          agentSetup: 'done',
          hideAgentSetupTitle: true
        });
      }
    },
    getNextStep: function getNextStep() {
      var progress = this.get('onBoardingStatus.progress');

      if (!progress.account_setup) {
        this.set('nextStep', 'email');
        //} else if (!progress.account_connected) {
        //  this.set('nextStep', 'social');
        //} else if (!progress.setup) {
        //  this.set('nextStep', 'messenger');
        //} else if (!progress.agent_added) {
        //  this.set('nextStep', 'agents');
      } else {
        this.set('nextStep', null);
      }
    },


    redirectToTwitterAuthenticationEndpoint: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(e) {
      var link;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              e.stopPropagation();
              this.set('isTwitterloading', true);
              _context.next = 4;
              return this.get('store').queryRecord('oauth-link', { callback: '/agent/welcome#twitter' });

            case 4:
              link = _context.sent;

              window.location.href = link.get('id');

            case 6:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    saveAgents: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(invitation) {
      var adapter, data, emails, errors, regex;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              adapter = getOwner(this).lookup('adapter:application');
              data = {
                users: invitation.get('users').map(function (user) {
                  var obj = user.getProperties('fullname', 'email');
                  obj.role_id = user.get('role.id') || null;
                  obj.team_ids = user.get('teams').mapBy('id');
                  return obj;
                })
              };


              invitation.get('users').forEach(function (user) {
                return user.set('errors', null);
              });

              _context2.prev = 3;
              _context2.next = 6;
              return adapter.ajax(adapter.namespace + '/users/invite', 'POST', { data: data });

            case 6:

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('admin.staff.members_invited.notification'),
                autodismiss: true
              });

              emails = data.users.map(function (user) {
                return user.email;
              });

              this.set('invitation.emails', emails);
              this.set('agentSetup', 'done');
              this.set('hideAgentSetupTitle', true);
              this.onComplete();

              this.get('onBoardingStatus').updateStatus('agent_added', { 'user.agent_added': '1' });
              _context2.next = 20;
              break;

            case 15:
              _context2.prev = 15;
              _context2.t0 = _context2['catch'](3);
              errors = _context2.t0.errors;
              regex = /users\/(\d+)\/(\w+)/;

              errors.forEach(function (error) {
                var pointer = error.pointer;

                if (!pointer) {
                  return;
                }
                var results = pointer.match(regex);
                var index = results[1];
                var field = results[2];
                var message = error.message;
                switch (field) {
                  case 'role_id':
                    field = 'role';
                    break;
                  case 'team_ids':
                    field = 'teams';
                    break;
                }
                var user = invitation.get('users').objectAt(index);
                if (!user.get('errors')) {
                  user.set('errors', {});
                }

                if (!user.get('errors.' + field)) {
                  user.set('errors.' + field, A([]));
                }

                user.get('errors.' + field).pushObject({ message: message });
              });

            case 20:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this, [[3, 15]]);
    })).drop(),

    saveTeam: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var defaultTeam, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, team;

      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return this.get('store').findRecord('team', 1);

            case 2:
              defaultTeam = _context3.sent;

              defaultTeam.set('title', this.get('defaultTeamTitle'));
              _context3.next = 6;
              return defaultTeam.save();

            case 6:
              _iteratorNormalCompletion = true;
              _didIteratorError = false;
              _iteratorError = undefined;
              _context3.prev = 9;
              _iterator = this.get('newTeams')[Symbol.iterator]();

            case 11:
              if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
                _context3.next = 18;
                break;
              }

              team = _step.value;
              _context3.next = 15;
              return this.get('store').createRecord('team', {
                title: team.value
              }).save();

            case 15:
              _iteratorNormalCompletion = true;
              _context3.next = 11;
              break;

            case 18:
              _context3.next = 24;
              break;

            case 20:
              _context3.prev = 20;
              _context3.t0 = _context3['catch'](9);
              _didIteratorError = true;
              _iteratorError = _context3.t0;

            case 24:
              _context3.prev = 24;
              _context3.prev = 25;

              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }

            case 27:
              _context3.prev = 27;

              if (!_didIteratorError) {
                _context3.next = 30;
                break;
              }

              throw _iteratorError;

            case 30:
              return _context3.finish(27);

            case 31:
              return _context3.finish(24);

            case 32:
              _context3.next = 34;
              return this.get('onBoardingStatus').updateStatus('team_added', { 'user.team_added': '1' });

            case 34:
              this.set('teamSetup', 'done');
              this.onComplete();

            case 36:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this, [[9, 20, 24, 32], [25,, 27, 31]]);
    })),

    fetchAvailablePages: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      var _this5 = this;

      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              _context4.next = 2;
              return this.get('store').query('facebook-page', { state: 'AVAILABLE' }).then(function (pages) {
                _this5.set('socialSetup', 'connectpages');
                _this5.set('fetchingPages', false);
                _this5.set('availablePages', pages);
                if (pages.get('length')) {
                  _this5.set('importPagesButtonDisabled', false);
                }
              });

            case 2:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })).drop(),

    _unloadUnsavedMailboxes: function _unloadUnsavedMailboxes() {
      var store = this.get('store');
      var mailboxes = store.peekAll('mailbox');
      var newMailboxes = mailboxes.filterBy('isNew');

      newMailboxes.forEach(function (mailbox) {
        return store.unloadRecord(mailbox);
      });
    },


    publishMessenger: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5() {
      var _this6 = this;

      var brandId, name, contents;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              brandId = this.get('brand.firstObject.id');
              name = 'helpcenter_kayako_messenger';
              contents = this.get('generatedCode');
              _context5.next = 5;
              return this.get('store').adapterFor('template').updateTempalte(brandId, name, contents).finally(function () {
                _this6.set('messengerSetup', 'embedCode');
              });

            case 5:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })),

    actions: {
      showPopup: function showPopup(index) {
        this.setProperties({
          noModalTitle: false,
          activePopup: index
        });
      },
      cancel: function cancel() {
        this.set('activePopup', 0);
      },
      startEmail: function startEmail() {
        this.set('emailStep', 'initial');
      },
      connectEmailInput: function connectEmailInput() {
        this.set('emailStep', 'connect');
      },
      connectEmail: function connectEmail(e) {
        var _this7 = this;

        e.preventDefault();

        var mailbox = this.get('emailToBeConnected');
        var adapter = this.get('store').adapterFor('mailbox');

        mailbox.save().then(function () {
          return adapter.makeDefault(mailbox);
        }).then(function () {
          return _this7.set('emailStep', 'emailForwarding');
        });
      },
      completeOnboarding: function completeOnboarding() {

        // Hide the modal
        this.set('activePopup', 0);
        // Show the loader
        this.set('isLoading', true);

        next(this, function () {
          // Mark all onboarding steps as completed
          this.get('onBoardingStatus').updateStatus('account_setup', { 'email.account_setup': '1' });
          this.get('onBoardingStatus').updateStatus('account_connected', { 'social.account_connected': '1' });
          this.get('onBoardingStatus').updateStatus('setup', { 'messenger.setup': '1' });
          this.get('onBoardingStatus').updateStatus('team_added', { 'user.team_added': '1' });
          this.get('onBoardingStatus').updateStatus('agent_added', { 'user.agent_added': '1' });

          // Redirect to the main agent page
          this.get('routing.router').transitionTo('session.agent.cases.index');
        });
      },
      emailConnected: function emailConnected() {
        this.set('emailStep', 'connected');
        this.send('emailDone');
      },
      emailDone: function emailDone() {
        this.set('hideEmailSetupTitle', true);

        this.get('onBoardingStatus').updateStatus('account_setup', { 'email.account_setup': '1' });
        this.get('onBoardingStatus').updateStatus('account_connected', { 'social.account_connected': '1' });
        this.get('onBoardingStatus').updateStatus('setup', { 'messenger.setup': '1' });
        this.get('onBoardingStatus').updateStatus('team_added', { 'user.team_added': '1' });
        this.get('onBoardingStatus').updateStatus('agent_added', { 'user.agent_added': '1' });

        this.onComplete();
        this.get('onBoardingStatus').updateStatus('account_setup', { 'email.account_setup': '1' });
      },
      createEmailInput: function createEmailInput() {
        this.set('emailStep', 'create');
      },
      createEmail: function createEmail(e) {
        var _this8 = this;

        e.preventDefault();
        /**
          Since we are doing string concatanation, we need to make sure email
          name does exists before doing so.
        */
        var address = this.get('createEmailName') ? '' + this.get('createEmailName') + this.get('fullDomain') : null;

        var emailModel = this.get('store').createRecord('mailbox', {
          address: address
        });

        this.set('emailToBeCreated', emailModel);

        if (this.get('createEmailName') === 'support') {
          this.set('emailStep', 'created');
          this.send('emailDone');
          return;
        }

        var mailbox = this.get('emailToBeCreated');
        var adapter = this.get('store').adapterFor('mailbox');

        mailbox.save().then(function () {
          return adapter.makeDefault(mailbox);
        }).then(function () {
          _this8.set('emailStep', 'created');
          _this8.send('emailDone');
        });
      },
      connectSocial: function connectSocial() {
        this.set('socialSetup', 'connect');
      },
      skipSocialSetup: function skipSocialSetup() {
        this.set('activePopup', 3);
        this.set('grayoutSocial', true);
        this.get('onBoardingStatus').updateStatus('account_connected', { 'social.account_connected': '1' });
      },
      redirectToFacebookAuthenticationEndpoint: function redirectToFacebookAuthenticationEndpoint(e) {
        e.stopPropagation();
        this.set('isFacebookConnecting', true);
        var store = this.get('store');

        store.queryRecord('oauth-link', { callback: '/agent/welcome#facebook' }).then(function (link) {
          window.location.href = link.get('id');
        });
      },
      importFacebookPages: function importFacebookPages() {
        var _this9 = this;

        var adapter = getOwner(this).lookup('adapter:application');
        var url = adapter.namespace + '/facebook/pages';

        this.set('importingPages', true);

        adapter.ajax(url, 'POST', {
          data: {
            page_ids: this.get('availablePages').filterBy('import', true).map(function (page) {
              return page.get('id');
            }).join(',')
          }
        }).then(function (pagesPayload) {
          _this9.get('store').pushPayload(pagesPayload);

          var pages = pagesPayload.facebook_pages.map(function (page) {
            return _this9.get('store').peekRecord('facebook-page', page.id)._internalModel;
          });

          _this9.get('pages').addObjects(pages);

          _this9.setProperties({
            importingPages: false,
            socialSetup: 'connect',
            facebookButtonDisabled: true,
            facebookPagesConnected: true
          });

          _this9.get('notification').add({
            type: 'success',
            title: _this9.get('i18n').t('onboarding.setup.popup.social.connect.facebookpagessuccess'),
            autodismiss: true
          });
        }, function () {
          _this9.set('importingPages', false);
        });
      },
      socialSetupDone: function socialSetupDone() {
        this.set('socialSetup', 'done');
        this.onComplete();
        this.get('onBoardingStatus').updateStatus('account_connected', { 'social.account_connected': '1' });
      },
      copiedToClipboard: function copiedToClipboard() {
        this.get('notification').add({
          type: 'success',
          title: this.get('i18n').t('generic.copied_to_clipboard'),
          autodismiss: true
        });
      },
      messengerSetupDone: function messengerSetupDone() {
        this.set('messengerSetup', 'done');
        this.onComplete();
        this.get('onBoardingStatus').updateStatus('setup', { 'messenger.setup': '1' });
      },
      addAgents: function addAgents() {
        this.set('agentSetup', 'add');
      },
      skipAgents: function skipAgents() {
        this.set('activePopup', 0);
        this.set('grayoutAgents', true);
        this.get('onBoardingStatus').updateStatus('agent_added', { 'user.agent_added': '1' });
      },
      rowsChanged: function rowsChanged(action, rowsCount) {
        this.set('agentsRows', rowsCount);
      },
      addTeam: function addTeam() {
        this.set('teamSetup', 'add');
      },
      skipTeamSetup: function skipTeamSetup() {
        this.set('activePopup', 5);
        this.get('onBoardingStatus').updateStatus('team_added', { 'user.team_added': '1' });
      },
      addMoreTeam: function addMoreTeam() {
        this.get('newTeams').pushObject({ value: '' });
      },
      removeTeam: function removeTeam(team) {
        this.get('newTeams').removeObject(team);
      }
    }
  });
});