define('frontend-cp/components/ko-admin/fields/form/option/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    // HTML
    tagName: '',

    // Attributes
    option: null,
    isCaseField: null,

    // Services
    locale: service(),
    store: service(),

    // CPs
    valueLocale: computed('option.values.[]', 'locale.accountDefaultLocaleCode', function () {
      var values = this.get('option.values');
      return values.findBy('locale', this.get('locale.accountDefaultLocaleCode'));
    }),

    actions: {
      setTag: function setTag(tagName) {
        this.set('option.tag', tagName);
      },
      setValue: function setValue(translation) {
        var valueLocale = this.get('valueLocale');

        if (valueLocale) {
          valueLocale.set('translation', translation);
        }
      }
    }
  });
});