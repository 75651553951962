define('frontend-cp/session/admin/automation/macros/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Route = Ember.Route;
  var EmberObject = Ember.Object;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    store: service(),
    i18n: service(),

    model: function model() {
      var _getProperties = this.getProperties('store', 'i18n'),
          store = _getProperties.store,
          i18n = _getProperties.i18n;

      return RSVP.hash({
        priorities: store.findAll('case-priority'),
        caseTypes: store.findAll('case-type'),
        caseStatuses: store.findAll('case-status'),
        replyTypes: [{
          id: 'REPLY',
          value: i18n.findTranslationByKey('admin.macros.actions.reply_type.reply')
        }, {
          id: 'NOTE',
          value: i18n.findTranslationByKey('admin.macros.actions.reply_type.note')
        }],
        agents: store.query('user', { role: 'agent', limit: 500 }),
        teams: store.findAll('team'),
        macroDefinitions: this.store.findAll('macro-action-definition')
      });
    },
    setupController: function setupController(controller, model) {
      var referenceData = {
        actionDefinitions: this._actionDefinitions(model),
        teams: model.teams
      };

      controller.set('referenceData', referenceData);
    },
    _actionDefinitions: function _actionDefinitions(_ref) {
      var priorities = _ref.priorities,
          caseTypes = _ref.caseTypes,
          caseStatuses = _ref.caseStatuses,
          replyTypes = _ref.replyTypes,
          agents = _ref.agents,
          teams = _ref.teams,
          macroDefinitions = _ref.macroDefinitions;

      var i18n = this.get('i18n');

      var peopleWithTeams = [{ id: 'UNASSIGNED', value: i18n.findTranslationByKey('admin.macros.assign.unassigned') }, { id: 'CURRENT_AGENT', value: i18n.findTranslationByKey('admin.macros.assign.current_agent') }];

      teams.forEach(function (team) {
        peopleWithTeams.pushObject({
          id: team.get('id'),
          value: team.get('title')
        });
      });

      agents.forEach(function (agent) {
        agent.get('teams').forEach(function (team) {
          peopleWithTeams.pushObject({
            id: [team.id, agent.id].filter(function (item) {
              return !!item;
            }).join('-'),
            value: team.get('title') + ' \\ ' + agent.get('fullName')
          });
        });
      });

      priorities = priorities.toArray().sortBy('level').map(function (priority) {
        return {
          id: priority.get('id'),
          value: priority.get('label')
        };
      });

      caseTypes = caseTypes.toArray().sortBy('id').map(function (caseType) {
        return {
          id: caseType.get('id'),
          value: caseType.get('label')
        };
      });

      caseStatuses = caseStatuses.toArray().sortBy('sortOrder').filter(function (item) {
        return ['New', 'Closed'].indexOf(item.get('label')) === -1;
      }).map(function (status) {
        return {
          id: status.get('id'),
          value: status.get('label')
        };
      });

      var additionalMacroDefinitions = macroDefinitions.toArray().map(function (macroDefinition) {
        return EmberObject.create({
          groupLabel: computed.alias('label'),
          inputType: macroDefinition.get('inputType'),
          valueType: macroDefinition.get('valueType'),
          label: macroDefinition.get('label'),
          name: macroDefinition.get('name'),
          options: macroDefinition.get('options'),
          values: Object.entries(macroDefinition.get('values')).map(function (_ref2) {
            var _ref3 = _slicedToArray(_ref2, 2),
                id = _ref3[0],
                value = _ref3[1];

            return { id: id, value: value };
          })
        });
      });

      return [EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'PLAIN-TEXT',
        valueType: 'STRING',
        label: i18n.findTranslationByKey('admin.macros.actions.reply_contents.label'),
        name: 'reply-contents',
        options: ['CHANGE'],
        values: replyTypes
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'OPTIONS',
        valueType: 'STRING',
        label: i18n.findTranslationByKey('admin.macros.actions.reply_type.label'),
        name: 'reply-type',
        options: ['CHANGE'],
        values: replyTypes
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'CASCADING_SELECT',
        valueType: 'STRING',
        label: i18n.findTranslationByKey('admin.macros.actions.assignee.label'),
        name: 'assignee',
        options: ['CHANGE'],
        values: peopleWithTeams
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'OPTIONS',
        valueType: 'NUMERIC',
        label: i18n.findTranslationByKey('admin.macros.actions.status.label'),
        name: 'status',
        options: ['CHANGE'],
        values: caseStatuses
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'OPTIONS',
        valueType: 'NUMERIC',
        label: i18n.findTranslationByKey('admin.macros.actions.type.label'),
        name: 'case-type',
        options: ['CHANGE'],
        values: caseTypes
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'TAGS',
        valueType: 'COLLECTION',
        label: i18n.findTranslationByKey('admin.macros.actions.add_tags.label'),
        name: 'add-tags',
        options: ['ADD'],
        values: ''
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'TAGS',
        valueType: 'COLLECTION',
        label: i18n.findTranslationByKey('admin.macros.actions.remove_tags.label'),
        name: 'remove-tags',
        options: ['REMOVE'],
        values: ''
      }), EmberObject.create({
        groupLabel: computed.alias('label'),
        inputType: 'OPTIONS',
        valueType: 'NUMERIC',
        label: i18n.findTranslationByKey('admin.macros.actions.priority.label'),
        name: 'priority',
        options: ['CHANGE', 'INCREASE', 'DECREASE'],
        values: priorities
      })].concat(_toConsumableArray(additionalMacroDefinitions));
    }
  });
});