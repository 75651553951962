define('frontend-cp/components/article-filters-panel/component', ['exports', 'npm:lodash'], function (exports, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.INITIAL_FILTER = undefined;

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var INITIAL_FILTER = exports.INITIAL_FILTER = { key: 'title', operator: 'contains', value: '' };

  exports.default = Component.extend({
    // Initialize the component's state
    filters: null,
    filterColumns: ['title', 'section', 'category', 'brand', 'author', 'status', 'updatedAt'],
    filterOperators: ['contains', 'equals', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'],
    isApplying: false,
    initialFilters: [_npmLodash.default.clone(INITIAL_FILTER)],

    notification: service(),
    i18n: service(),
    session: service(),

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.filters || this.filters.length === 0) {
        this.set('filters', _npmLodash.default.cloneDeep(this.get('initialFilters')));
      } else {
        this.set('filters', this.filter);
      }
    },
    clearErrors: function clearErrors(index) {
      this.set('filters.' + index + '.errors', undefined);
    },


    actions: {
      // Add a new filter row
      addFilter: function addFilter() {
        var filters = this.get('filters');
        filters.pushObject(_npmLodash.default.clone(INITIAL_FILTER));
        this.set('filters', filters);
      },


      // Update the key (column) of a filter
      updateFilterKey: function updateFilterKey(index, value) {
        this.set('filters.' + index + '.key', value);
        this.clearErrors(index);
      },


      // Update the operator of a filter
      updateFilterOperator: function updateFilterOperator(index, value) {
        this.set('filters.' + index + '.operator', value);
        this.clearErrors(index);
      },


      // Update the value of a filter
      updateFilterValue: function updateFilterValue(index, event) {
        this.set('filters.' + index + '.value', event.target.value);
        this.clearErrors(index);
      },


      // Remove a filter row, but prevent removing the last one
      removeFilter: function removeFilter(index) {
        var filters = this.get('filters');

        // Ensure there's always at least one filter row
        if (filters.length > 1) {
          filters.removeAt(index);
          this.set('filters', filters);
        }
      },
      applyFilters: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
          var filters, errors, index, filter, filterErrors;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  _context.prev = 0;

                  this.set('isApplying', true);
                  filters = this.get('filters');
                  errors = [];

                  // Check if the value is mandatory for specific operators

                  for (index = 0; index < filters.length; index++) {
                    filter = filters[index];
                    filterErrors = {};


                    if (['contains', 'startsWith', 'endsWith'].includes(filter.operator) && !filter.value) {
                      if (!filterErrors.value) {
                        filterErrors.value = [];
                      }
                      filterErrors.value.push({ message: this.get('i18n').t('admin.knowledgebase.errors.value_required') });
                    }

                    if (Object.keys(filterErrors).length > 0) {
                      errors.push(filterErrors);
                      this.set('filters.' + index + '.errors', filterErrors);
                    }
                  }

                  if (!(errors.length > 0)) {
                    _context.next = 7;
                    break;
                  }

                  return _context.abrupt('return');

                case 7:
                  _context.next = 9;
                  return this.onApply(filters);

                case 9:
                  _context.next = 14;
                  break;

                case 11:
                  _context.prev = 11;
                  _context.t0 = _context['catch'](0);

                  // Handle any unexpected errors
                  this.get('notification').error(this.get('i18n').t('generic.generic_error'));

                case 14:
                  _context.prev = 14;

                  this.set('isApplying', false);
                  return _context.finish(14);

                case 17:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this, [[0, 11, 14, 17]]);
        }));

        function applyFilters() {
          return _ref.apply(this, arguments);
        }

        return applyFilters;
      }(),


      // Reset all filters back to the initial filter
      resetFilters: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _context2.prev = 0;

                  this.set('isApplying', true);
                  this.set('filters', _npmLodash.default.cloneDeep(this.get('initialFilters')));
                  _context2.next = 5;
                  return this.onApply(this.get('filters'));

                case 5:
                  _context2.next = 10;
                  break;

                case 7:
                  _context2.prev = 7;
                  _context2.t0 = _context2['catch'](0);

                  // Handle any unexpected errors
                  this.get('notification').error(this.get('i18n').t('generic.generic_error'));

                case 10:
                  _context2.prev = 10;

                  this.set('isApplying', false);
                  return _context2.finish(10);

                case 13:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this, [[0, 7, 10, 13]]);
        }));

        function resetFilters() {
          return _ref2.apply(this, arguments);
        }

        return resetFilters;
      }()
    }
  });
});