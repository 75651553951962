define('frontend-cp/adapters/side-conversation', ['exports', 'frontend-cp/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function normalizeConversationData(data, resources) {
    if (Array.isArray(data)) {
      return data.map(function (item) {
        return normalizeConversationData(item, resources);
      }).reverse();
    } else if (data && (typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object') {
      // Check if the object is a resource reference
      if (data.id && data.resource_type && Object.keys(data).length === 2) {
        var resourceType = data.resource_type;
        var resourceId = data.id;
        var resourceData = resources[resourceType] && resources[resourceType][resourceId];
        if (resourceData) {
          return normalizeConversationData(resourceData, resources);
        }
      }
      // Recursively normalize each property
      var normalizedObject = {};
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          normalizedObject[key] = normalizeConversationData(data[key], resources);
        }
      }
      return normalizedObject;
    }
    // Return data if it's neither an array nor an object
    return data;
  }

  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'side-conversations';
    },
    createNewSideConversation: function createNewSideConversation(caseId, subject, contents, channelOptions, attachmentFileIds) {
      var url = this.namespace + '/cases/' + caseId + '/' + this.pathForType();
      var data = {
        subject: subject,
        contents: contents,
        channel_options: channelOptions,
        attachment_file_ids: attachmentFileIds
      };

      return this.ajax(url, 'POST', {
        contentType: 'application/json',
        data: data
      }).then(function (response) {
        var normalizedData = normalizeConversationData(response.data, response.resources);
        return normalizedData;
      });
    },
    replyToSideConversation: function replyToSideConversation(conversationId, contents, channelOptions, attachmentFileIds) {
      var url = this.namespace + '/side-conversations/' + conversationId + '/messages?include=case_message';
      var data = {
        contents: contents,
        channel_options: channelOptions,
        attachment_file_ids: attachmentFileIds
      };

      return this.ajax(url, 'POST', {
        contentType: 'application/json',
        data: data
      }).then(function (response) {
        var normalizedData = normalizeConversationData(response.data, response.resources);
        return normalizedData;
      });
    },
    loadSideConversation: function loadSideConversation(conversationId, query) {
      var url = this.namespace + '/side-conversations/' + conversationId + '/messages?include=*';
      return this.ajax(url, 'GET', { data: query }).then(function (response) {
        var normalizedData = normalizeConversationData(response.data, response.resources);
        return normalizedData;
      });
    },
    updateStatus: function updateStatus(caseId, conversationId, status) {
      var url = this.namespace + '/cases/' + caseId + '/side-conversations/' + conversationId + '/status';
      return this.ajax(url, 'PUT', { data: { status: status } });
    },
    query: function query(store, type, _query) {
      // Construct the URL for the request
      var url = this.namespace + '/cases/' + _query.caseId + '/' + this.pathForType();
      // Make the AJAX request with the query parameters
      return this.ajax(url, 'GET', { data: _query });
    }
  });
});