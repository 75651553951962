define('frontend-cp/components/articles-view-list/component', ['exports', 'ember-concurrency', 'ember-diff-attrs', 'frontend-cp/components/article-filters-panel/component', 'npm:lodash'], function (exports, _emberConcurrency, _emberDiffAttrs, _component, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Promise = Ember.RSVP.Promise;
  exports.default = Component.extend({
    store: service(),
    router: service(),
    confirmation: service(),
    i18n: service(),
    notification: service(),
    locale: service(),
    session: service(),
    articleEvents: service(),

    articles: [],
    currentPage: 1,
    itemsPerPage: 15,
    totalCount: 0,
    loadingPage: -1,
    isLoading: false,
    selectedArticleIds: [],
    allRowsSelected: false,
    isDeletingArticles: false,
    showFilters: false,
    selectedStatus: 'ALL',
    filters: [],
    isHidden: false,
    previousStatus: null,
    initialFilters: [_npmLodash.default.clone(_component.INITIAL_FILTER)],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('previousStatus', this.get('selectedStatus'));
      this.fetchArticlesForPage(this.currentPage);
    },


    // Task to handle both visibility and status changes in a coordinated way
    handleExternalChanges: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var hidden, status, previousStatus, statusFilter;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return new Promise(function (resolve) {
                return setTimeout(resolve, 10);
              });

            case 2:
              hidden = this.get('isHidden');
              status = this.get('selectedStatus');
              previousStatus = this.get('previousStatus');

              // Check if the status has actually changed

              if (status !== previousStatus) {
                statusFilter = this.generateFilterFromStatus(status);

                this.set('currentPage', 1);
                this.set('filters', statusFilter);
                this.set('previousStatus', status);
              }

              // Check if the component is now visible

              if (hidden) {
                _context.next = 9;
                break;
              }

              _context.next = 9;
              return this.fetchArticlesForPage(this.get('currentPage'));

            case 9:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    didReceiveAttrs: (0, _emberDiffAttrs.default)('isHidden', 'selectedStatus', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, arguments);
      if (changedAttrs && !(changedAttrs.isHidden || changedAttrs.selectedStatus)) {
        return;
      }

      this.get('handleExternalChanges').perform();
    }),

    generateFilterFromStatus: function generateFilterFromStatus(status) {
      var currentUser = this.get('session.user');

      var filters = [_npmLodash.default.clone(_component.INITIAL_FILTER)];

      switch (status) {
        case 'PUBLISHED':
          filters = [{ key: 'status', operator: 'equals', value: 'PUBLISHED' }, { key: 'author', operator: 'equals', value: currentUser.get('fullName') }];
          break;
        case 'DRAFT':
          filters = [{ key: 'status', operator: 'equals', value: 'DRAFT' }];
          break;
        case 'ALL':
        default:
          break;
      }

      this.set('initialFilters', _npmLodash.default.cloneDeep(filters));
      return filters;
    },


    totalPages: computed('totalCount', 'itemsPerPage', function () {
      return Math.ceil(this.get('totalCount') / this.get('itemsPerPage'));
    }),

    // Computed property to determine which pages to display for pagination
    visiblePages: computed('currentPage', 'totalPages', function () {
      var totalPages = this.get('totalPages');
      var currentPage = this.get('currentPage');
      var maxPages = 9;
      var startPage = void 0,
          endPage = void 0;

      if (totalPages <= maxPages) {
        // Total pages less than max, so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // More than max pages, so calculate start and end pages
        var middle = Math.floor(maxPages / 2);
        if (currentPage <= middle) {
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + middle >= totalPages) {
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - middle;
          endPage = currentPage + middle;
        }
      }
      return Array.from({ length: endPage - startPage + 1 }, function (_, i) {
        return startPage + i;
      });
    }),

    fetchArticlesForPage: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(page) {
        var limit, offset, store, articleAdapter, result, defaultLocale, userLocale;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.prev = 0;

                this.set('isLoading', true);
                this.set('loadingPage', page);
                limit = this.get('itemsPerPage');
                offset = (page - 1) * limit;
                store = this.get('store');
                articleAdapter = store.adapterFor('article');
                _context2.next = 9;
                return articleAdapter.fetchArticles({ offset: offset, limit: limit, filters: this.get('filters') });

              case 9:
                result = _context2.sent;
                defaultLocale = this.get('locale.accountDefaultLocaleCode');
                userLocale = this.get('session.user.locale.locale');

                result.data.forEach(function (a) {
                  var titleObj = a.titles.find(function (t) {
                    return t.locale === defaultLocale;
                  }) || a.titles.find(function (t) {
                    return t.locale === userLocale;
                  }) || a.titles[0];
                  a.title = titleObj.translation;

                  var sectionObj = a.sections.find(function (s) {
                    return s.locale === defaultLocale;
                  }) || a.sections.find(function (s) {
                    return s.locale === userLocale;
                  }) || a.sections[0];
                  a.section = sectionObj.translation;

                  var categoryObj = a.categories.find(function (c) {
                    return c.locale === defaultLocale;
                  }) || a.categories.find(function (c) {
                    return c.locale === userLocale;
                  }) || a.categories[0];
                  a.category = categoryObj.translation;
                });
                this.set('isDeletingArticles', false);
                this.set('selectedArticleIds', []);
                this.set('articles', result.data);
                this.set('totalCount', result.totalCount);
                this.set('loadingPage', -1);
                this.set('currentPage', page);
                _context2.next = 24;
                break;

              case 21:
                _context2.prev = 21;
                _context2.t0 = _context2['catch'](0);

                this.get('notification').error(this.get('i18n').t('admin.knowledgebase.errors.fetch_articles'));

              case 24:
                _context2.prev = 24;

                this.set('isLoading', false);
                return _context2.finish(24);

              case 27:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this, [[0, 21, 24, 27]]);
      }));

      function fetchArticlesForPage(_x) {
        return _ref.apply(this, arguments);
      }

      return fetchArticlesForPage;
    }(),


    trashArticles: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var _this = this;

      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmationBody: 'admin.knowledgebase.trasharticles.confirmation'
              }).then(function () {
                _this.set('isDeletingArticles', true);
                _this.set('isLoading', true);
                var store = _this.get('store');
                var articleAdapter = store.adapterFor('article');
                return articleAdapter.deleteByIds(_this.get('selectedArticleIds').join(',')).then(function () {
                  var selectedArticleIdsCount = _this.get('selectedArticleIds').length;
                  var articlesCount = _this.get('articles').length;
                  var currentPage = _this.get('currentPage');
                  _this.set('allRowsSelected', false);
                  _this.set('isLoading', false);
                  if (selectedArticleIdsCount === articlesCount && currentPage > 1) {
                    _this.fetchArticlesForPage(currentPage - 1);
                  } else {
                    _this.fetchArticlesForPage(currentPage);
                  }
                  _this.get('articleEvents').articleDeleted();
                }).catch(function () {
                  _this.get('notification').error(_this.get('i18n').t('admin.knowledgebase.trasharticles.failed'));
                  _this.set('isLoading', false);
                  _this.set('isDeletingArticles', false);
                });
              });

            case 2:
              return _context3.abrupt('return', _context3.sent);

            case 3:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })),

    actions: {
      previousPage: function previousPage() {
        if (this.get('currentPage') > 1) {
          var previousPage = this.get('currentPage') - 1;
          this.fetchArticlesForPage(previousPage);
        }
      },
      firstPage: function firstPage() {
        if (this.get('currentPage') === 1) {
          return;
        }
        this.fetchArticlesForPage(1);
      },
      goToPage: function goToPage(page) {
        if (page === this.get('currentPage')) {
          return;
        }
        this.fetchArticlesForPage(page);
      },
      nextPage: function nextPage() {
        if (this.get('currentPage') < this.get('totalPages')) {
          var nextPage = this.get('currentPage') + 1;
          this.fetchArticlesForPage(nextPage);
        }
      },
      goToLastPage: function goToLastPage() {
        if (this.get('currentPage') === this.get('totalPages')) {
          return;
        }
        var lastPage = this.get('totalPages');
        this.fetchArticlesForPage(lastPage);
      },
      openArticle: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(articleId) {
          return regeneratorRuntime.wrap(function _callee4$(_context4) {
            while (1) {
              switch (_context4.prev = _context4.next) {
                case 0:
                  if (!this.get('isLoading')) {
                    _context4.next = 2;
                    break;
                  }

                  return _context4.abrupt('return');

                case 2:
                  this.set('isLoading', true);
                  _context4.next = 5;
                  return this.get('router').transitionTo('session.agent.knowledgebase.article-view', articleId);

                case 5:
                  this.set('isLoading', false);

                case 6:
                case 'end':
                  return _context4.stop();
              }
            }
          }, _callee4, this);
        }));

        function openArticle(_x2) {
          return _ref2.apply(this, arguments);
        }

        return openArticle;
      }(),
      handleAllRowsSelection: function handleAllRowsSelection(value) {
        if (value) {
          this.set('allRowsSelected', true);
          this.set('selectedArticleIds', this.get('articles').mapBy('id'));
        } else {
          this.set('allRowsSelected', false);
          this.set('selectedArticleIds', []);
        }
      },
      handleRowSelection: function handleRowSelection(articleId, value) {
        var selectedArticleIds = [].concat(_toConsumableArray(this.get('selectedArticleIds')));
        if (value) {
          selectedArticleIds.push(articleId);
        } else {
          selectedArticleIds.removeObject(articleId);
        }
        this.set('selectedArticleIds', selectedArticleIds);
      },

      handleApplyFilters: function handleApplyFilters(filters, reset) {
        // Handle the filters passed back from the child component
        this.set('currentPage', 1);
        this.set('filters', filters);
        this.fetchArticlesForPage(this.get('currentPage'));
        this.set('showFilters', false);
      }
    }
  });
});