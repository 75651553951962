define('frontend-cp/components/ko-timeline-2/list/ai-suggestion-bubble/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var guidFor = Ember.guidFor;


  var initialTextLength = 250;

  exports.default = Component.extend({

    metrics: service(),
    store: service(),
    tagName: '',
    // Attributes
    text: '',
    htmlContent: null,
    isExpanded: false,
    uniqueId: null,
    contextTitle: '',

    setReplyContent: null,
    openReply: null,
    submitReply: null,

    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.uniqueId = guidFor(this);
      if (!this.htmlContent) {
        this.set('htmlContent', this.text);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var parent = this.get('parent');
      var event = this.get('event');
      this.get('metrics').trackEvent({
        event: 'Atlas AI - Viewed AI Generated Response',
        caseId: parent && parent.id || null,
        casePostUUID: event && event.get('uuid') || null
      });
    },


    truncatedText: computed('text', 'isExpanded', function () {
      var text = this.get('text');
      if (this.isExpanded) {
        return text;
      } else {
        if (text.length > initialTextLength) {
          return text.slice(0, text.slice(0, initialTextLength).lastIndexOf(' ')) + '...';
        } else {
          return text;
        }
      }
    }),

    showReadMoreButton: computed('text', function () {
      return this.get('text').length > initialTextLength;
    }),

    actions: {
      toggleExpand: function toggleExpand() {
        this.toggleProperty('isExpanded');
      },
      pasteAsIs: function pasteAsIs() {
        var parent = this.get('parent');
        var event = this.get('event');
        this.get('metrics').trackEvent({
          event: 'Atlas AI - Paste as is',
          caseId: parent && parent.id || null,
          casePostUUID: event && event.get('uuid') || null
        });
        this.setReplyContent('' + this.htmlContent);
        this.openReply();
        this.submitReply();
      },
      editResponse: function editResponse() {
        var parent = this.get('parent');
        var event = this.get('event');
        this.get('metrics').trackEvent({
          event: 'Atlas AI - Edit Response',
          caseId: parent && parent.id || null,
          casePostUUID: event && event.get('uuid') || null
        });
        this.setReplyContent('' + this.htmlContent);
      },
      summarizeTranscription: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
          var store, caseId, caseTranscriptionAdapter, transcription;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  this.set('isSummarizingTranscription', true);
                  store = this.get('store');
                  caseId = this.get('parent').id;
                  caseTranscriptionAdapter = store.adapterFor('case-ai-transcription');
                  _context.prev = 4;
                  _context.next = 7;
                  return caseTranscriptionAdapter.summarizeTranscription(caseId, this.htmlContent);

                case 7:
                  transcription = _context.sent;

                  this.set('summarizedTrancription', transcription);
                  this.get('metrics').trackEvent({
                    event: 'AI Transcription Summary Added to Case',
                    caseId: caseId
                  });
                  /*this.get('fetchNewerPosts').perform();*/
                  _context.next = 15;
                  break;

                case 12:
                  _context.prev = 12;
                  _context.t0 = _context['catch'](4);

                  this.get('notificationService').error(this.get('i18n').t('generic.generic_error'));

                case 15:
                  _context.prev = 15;

                  this.set('isSummarizingTranscription', false);
                  return _context.finish(15);

                case 18:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this, [[4, 12, 15, 18]]);
        }));

        function summarizeTranscription() {
          return _ref.apply(this, arguments);
        }

        return summarizeTranscription;
      }()
    }
  });
});