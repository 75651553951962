define("frontend-cp/components/ko-timeline-2/list/item/attachment/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "flap-hide": "ko-timeline-2_list_item_attachment__flap-hide_1ehwep",
    "attachment-container": "ko-timeline-2_list_item_attachment__attachment-container_1ehwep",
    "attachment": "ko-timeline-2_list_item_attachment__attachment_1ehwep",
    "details": "ko-timeline-2_list_item_attachment__details_1ehwep",
    "thumbnail": "ko-timeline-2_list_item_attachment__thumbnail_1ehwep",
    "no-thumb": "ko-timeline-2_list_item_attachment__no-thumb_1ehwep",
    "thumbnail-link": "ko-timeline-2_list_item_attachment__thumbnail-link_1ehwep",
    "small": "ko-timeline-2_list_item_attachment__small_1ehwep",
    "meta": "ko-timeline-2_list_item_attachment__meta_1ehwep",
    "actions": "ko-timeline-2_list_item_attachment__actions_1ehwep",
    "action": "ko-timeline-2_list_item_attachment__action_1ehwep",
    "preview": "ko-timeline-2_list_item_attachment__preview_1ehwep",
    "download": "ko-timeline-2_list_item_attachment__download_1ehwep",
    "name": "ko-timeline-2_list_item_attachment__name_1ehwep",
    "name-element": "ko-timeline-2_list_item_attachment__name-element_1ehwep",
    "size": "ko-timeline-2_list_item_attachment__size_1ehwep",
    "full-screen-loader": "ko-timeline-2_list_item_attachment__full-screen-loader_1ehwep"
  };
});