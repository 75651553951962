define('frontend-cp/components/ko-select/multiple/pill/component', ['exports', 'frontend-cp/utils/is-system-tag'], function (exports, _isSystemTag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    // Attributes
    all: null,
    select: null,
    option: null,
    searchField: null,
    removable: true,

    isRemovable: computed('option', 'removable', function () {
      return this.get('removable') && !(0, _isSystemTag.default)(this.get('option'));
    }),

    // HTML
    tagName: '',

    actions: {
      remove: function remove(object, e) {
        e.stopPropagation();
        e.preventDefault();
        var select = this.get('select');
        select.actions.choose(object);
      }
    }
  });
});