define('frontend-cp/adapters/case-ai-copilot', ['exports', 'frontend-cp/adapters/application', 'npm:html-to-text'], function (exports, _application, _npmHtmlToText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'ai-copilot';
    },
    generateSuggestion: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(caseId, text, context) {
        var response, suggestion;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.ajax('/api/v1/cases/' + caseId + '/ai-copilot', 'POST', {
                  data: { text: text, context: context }
                });

              case 2:
                response = _context.sent;
                suggestion = response.data.ai_copilot;

                if (suggestion) {
                  // Strip all HTML from the suggestion
                  suggestion = _npmHtmlToText.default.fromString(suggestion, {
                    wordwrap: false,
                    ignoreHref: true,
                    ignoreImage: true,
                    preserveNewlines: true
                  });
                }

                return _context.abrupt('return', suggestion);

              case 6:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function generateSuggestion(_x, _x2, _x3) {
        return _ref.apply(this, arguments);
      }

      return generateSuggestion;
    }()
  });
});