define("frontend-cp/components/ko-admin/apps/endpoints/form/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "select": "ko-admin_apps_endpoints_form__select_ncqmvx",
    "checkbox": "ko-admin_apps_endpoints_form__checkbox_ncqmvx",
    "auth-method": "ko-admin_apps_endpoints_form__auth-method_ncqmvx",
    "auth-options": "ko-admin_apps_endpoints_form__auth-options_ncqmvx",
    "header-pair": "ko-admin_apps_endpoints_form__header-pair_ncqmvx",
    "header-key": "ko-admin_apps_endpoints_form__header-key_ncqmvx",
    "header-value": "ko-admin_apps_endpoints_form__header-value_ncqmvx",
    "header-remove": "ko-admin_apps_endpoints_form__header-remove_ncqmvx",
    "header-add-btn": "ko-admin_apps_endpoints_form__header-add-btn_ncqmvx",
    "has-error": "ko-admin_apps_endpoints_form__has-error_ncqmvx"
  };
});